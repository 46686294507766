import { Gallery, Item } from "react-photoswipe-gallery";
import { StaticImage } from "gatsby-plugin-image";
import { Image as BuilderImage } from "@builder.io/react";
import { Builder } from "@builder.io/react";
import { toMutable } from "types/common";
import glass from "./images/glass.svg";
import * as styles from "./styles.module.scss";

const inputs = [
    {
        name: "title",
        type: "text",
        defaultValue: "From the Founder",
    },
    {
        name: "linkTo",
        type: "text",
        defaultValue: "/",
    },
    {
        name: "subtitle",
        type: "text",
        defaultValue:
            "With a career in the gaming industry, founder Nils Lind built the product out of curiosity and necessity to learn more about the Advertising industry. He has working knowledge with digital publishers where he found the need to have a complete tool to provide data transparency that enables assertive optimization.",
    },
    {
        name: "quoteSubtitle",
        type: "text",
        defaultValue:
            "“In my early days, I realized that no software was measuring the user lifetime value for the website data that could help publishers improve the revenue earned from advertising. This made me curious to develop a product that could provide data optimization and personalization of data catered to each publisher’’",
    },
    {
        name: "subtitle2",
        type: "text",
        defaultValue:
            "Nils built a network in the publishing industry and made a name in the community of industry experts.",
    },
    {
        name: "quoteSubtitle2",
        type: "text",
        defaultValue:
            "‘’I am a data-driven person and having an engineering background, I wanted to become a disrupter and a game changer in the Ad Tech industry’’",
    },
    {
        name: "subtitle3",
        type: "text",
        defaultValue:
            "He built a holistic SaaS product called the Yield Analytics to provide granular real-time data optimization in every time zone to solve hours of troubleshooting and reducing it to reliable and faster solutions in minutes. The company gradually developed and launched three other distinct products - Yield Manager, Traffic Shaping and Yield Optimizer - to cater to specific challenges for digital Publishers, Abitrage, AdNetworks, SSPs",
    },
    {
        name: "quoteSubtitle3",
        type: "text",
        defaultValue:
            "‘’My aim was to provide a unique dashboard catering to the needs of each publisher and also provide unique features like monitoring and alerts so that they don’t lose out on data discrepancies. What we do is provide granular real-time data, optimize it and make assertive changes’’",
    },
    {
        name: "switchMagnifier",
        type: "boolean",
        defaultValue: true,
        enum: [
            {
                value: true,
                label: "Yes",
            },
            {
                value: false,
                label: "No",
            },
        ],
    },
    {
        name: "imageOrGif",
        type: "file",
        allowedFileTypes: ["jpg", "png", "jpeg", "gif", "svg"],
    },
    {
        name: "imageOrGifHover",
        type: "file",
        allowedFileTypes: ["jpg", "png", "jpeg", "gif", "svg"],
    },
    {
        name: "imageOrGifAlt",
        type: "text",
        defaultValue: "Gain Bidder Intelligence",
    },
    {
        name: "imageOrGifWidth",
        type: "number",
        defaultValue: 1161,
    },
    {
        name: "imageOrGifHeight",
        type: "number",
        defaultValue: 714,
    },
] as const;

export const AboutUsFromTheFounder: BFC<typeof inputs> = ({
    title,
    linkTo,
    subtitle,
    quoteSubtitle,
    subtitle2,
    quoteSubtitle2,
    subtitle3,
    quoteSubtitle3,
    switchMagnifier,
    imageOrGif,
    imageOrGifHover,
    imageOrGifAlt,
    imageOrGifWidth,
    imageOrGifHeight,
}) => {
    return (
        <div className="container">
            <div className={`${styles.firstGlow} gradient`}>
                <StaticImage src="./images/about-us-from-the-founder-first-glow.svg" alt="" />
            </div>
            <div className={styles.wrapper}>
                <h2 className="d-block d-lg-none text-center">{title}</h2>
                <div className={styles.image}>
                    <div className="position-relative ">
                        {imageOrGifHover ? (
                            <img
                                onMouseOver={e => (e.currentTarget.src = imageOrGifHover)}
                                onMouseOut={e => (e.currentTarget.src = imageOrGif!)}
                                src={imageOrGif}
                                alt={imageOrGifAlt}
                                className="img-fluid"
                            />
                        ) : (
                            //@ts-ignore
                            <BuilderImage image={imageOrGif} lazy sizes="100%" altText={imageOrGifAlt} />
                        )}
                        {switchMagnifier && !imageOrGifHover && (
                            <Gallery>
                                <Item
                                    width={imageOrGifWidth}
                                    height={imageOrGifHeight}
                                    original={imageOrGif}
                                    alt={imageOrGifAlt}
                                >
                                    {({ ref, open }) => (
                                        // @ts-expect-error
                                        <div className="image_mask" ref={ref} onClick={open}>
                                            <img src={glass} alt="zoom image" />
                                        </div>
                                    )}
                                </Item>
                            </Gallery>
                        )}
                    </div>
                </div>
                <div className={styles.mainContent}>
                    <h2 className="d-none d-lg-block">{title}</h2>
                    <p className={styles.paragraph}>{subtitle}</p>
                    <div className={styles.quoteWrapper}>
                        <div className={`${styles.verticalLine} d-none d-lg-block`} />
                        <p className={styles.paragraphQuote}>{quoteSubtitle}</p>
                    </div>
                </div>
            </div>

            <div className={styles.dynamicParagraphs}>
                <p className={styles.paragraph}>{subtitle2}</p>

                <div className={styles.quoteWrapper}>
                    <div className={`${styles.shortVerticalLine} d-none d-lg-block`} />
                    <p className={styles.paragraphQuote}>{quoteSubtitle2}</p>
                </div>
            </div>

            <p className={styles.paragraph}>{subtitle3}</p>

            <div className={`${styles.quoteWrapper} mb-4`}>
                <div className={`${styles.shortVerticalLineDescription} d-none d-lg-block`} />
                <p className={styles.paragraphQuote}>{quoteSubtitle3}</p>
            </div>

            <a href={linkTo} className={styles.linkWrapper}>
                <span className={styles.linkText}>See what’s happening in the Press</span>
                <div className={`${styles.arrow}`}>
                    <StaticImage src="./images/about-us-from-the-founder-arrow.svg" alt="" />
                </div>
            </a>
        </div>
    );
};

Builder.registerComponent(AboutUsFromTheFounder, {
    name: "aboutUsFromTheFounder",
    inputs: toMutable(inputs),
});
