import { type PublicDimensionConfig } from "../types";
import { BUY_ADSET_DIMENSIONS } from "./buy_adset";
import buyBase from "./shared/buy";

const DIMENSIONS = {
    ...BUY_ADSET_DIMENSIONS,
    buy_report_ad_id: {
        label: "Ad ID",
    },
    buy_report_ad_name: {
        label: "Ad Name",
    },
} as const satisfies Record<string, Omit<PublicDimensionConfig, "id">>;

export type BuydAdDimensionsIds = keyof typeof DIMENSIONS;

export default buyBase.withDimensions(DIMENSIONS);
