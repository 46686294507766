import { Gallery, Item } from "react-photoswipe-gallery";
import { StaticImage } from "gatsby-plugin-image";
import { Builder } from "@builder.io/react";
import { Image as BuilderImage } from "@builder.io/react";
import { BOOK_CALL_LINK } from "components/constants";
import CustomButton from "components/CustomButton";
import { toMutable } from "types/common";
import glass from "./images/glass.svg";
import * as styles from "./styles.module.scss";

const inputs = [
    {
        name: "title",
        type: "text",
        defaultValue: "Still Struggling to match data from different sources?",
    },
    {
        name: "subtitle",
        type: "text",
        defaultValue: "You should try to play a different game, in real-time",
    },
    {
        name: "blockSuptitle",
        type: "text",
        defaultValue: "use first party data",
    },
    {
        name: "blockTitle",
        type: "text",
        defaultValue: "Single Source of Truth",
    },
    {
        name: "blockDescription",
        type: "text",
        defaultValue:
            "On-page Tracker - 1st Party Data Easily implemented on your website pages, AY tracker empowers your team to visualize data within seconds from the impression happening on the website. Edge-Optimized APIs available for data enhancement, easy to integrate - e.g AdSense,- which ensures high-quality data that meets requirements of Schrems II and GDPR. Lets Play!",
    },
    {
        name: "linkTo",
        type: "text",
        defaultValue: BOOK_CALL_LINK,
        required: true,
    },
    {
        name: "switchMagnifier",
        type: "boolean",
        defaultValue: true,
        enum: [
            {
                value: true,
                label: "Yes",
            },
            {
                value: false,
                label: "No",
            },
        ],
    },
    {
        name: "imageOrGif",
        type: "file",
        allowedFileTypes: ["jpg", "png", "jpeg", "gif", "svg"],
    },
    {
        name: "imageOrGifHover",
        type: "file",
        allowedFileTypes: ["jpg", "png", "jpeg", "gif", "svg"],
    },
    {
        name: "imageOrGifAlt",
        type: "text",
        defaultValue: "Gain Bidder Intelligence",
    },
    {
        name: "imageOrGifWidth",
        type: "number",
        defaultValue: 1269,
    },
    {
        name: "imageOrGifHeight",
        type: "number",
        defaultValue: 809,
    },
] as const;

export const AllRevenueSourceMatchData: BFC<typeof inputs> = ({
    title,
    subtitle,
    linkTo,
    blockSuptitle,
    blockTitle,
    blockDescription,
    switchMagnifier,
    imageOrGif,
    imageOrGifHover,
    imageOrGifAlt,
    imageOrGifWidth,
    imageOrGifHeight,
}) => {
    return (
        <div className="container">
            <div className={`${styles.firstGlow} gradient`}>
                <StaticImage src="./images/all-revenue-source-match-first-glow.svg" alt="" />
            </div>
            <div className={`${styles.secondGlow} gradient`}>
                <StaticImage src="./images/all-revenue-source-match-second-glow.svg" alt="" />
            </div>
            <div>
                <h2 className={styles.title}>{title}</h2>
                <p className={styles.subtitle}>{subtitle}</p>

                <div className="row align-items-center gx-5">
                    <div className="col-lg-6 order-lg-1 mt-4 mb-4 mt-lg-0 mb-lg-0">
                        <div className="mask-container  position-relative ">
                            {imageOrGifHover ? (
                                <img
                                    onMouseOver={e => (e.currentTarget.src = imageOrGifHover)}
                                    onMouseOut={e => (e.currentTarget.src = imageOrGif!)}
                                    src={imageOrGif}
                                    alt={imageOrGifAlt}
                                    className="img-fluid"
                                />
                            ) : (
                                //@ts-ignore
                                <BuilderImage image={imageOrGif} lazy sizes="100%" altText={imageOrGifAlt} />
                            )}
                            {switchMagnifier && !imageOrGifHover && (
                                <Gallery>
                                    <Item
                                        width={imageOrGifWidth}
                                        height={imageOrGifHeight}
                                        original={imageOrGif}
                                        alt={imageOrGifAlt}
                                    >
                                        {({ ref, open }) => (
                                            // @ts-expect-error
                                            <div className="image_mask" ref={ref} onClick={open}>
                                                <img src={glass} alt="zoom image" />
                                            </div>
                                        )}
                                    </Item>
                                </Gallery>
                            )}
                        </div>
                    </div>
                    <div className="col-lg-6 order-2 order-lg-1 mt-5 mt-lg-0">
                        <p className={styles.suptitle}>{blockSuptitle}</p>
                        <h2>{blockTitle}</h2>
                        <p className={styles.description}>{blockDescription}</p>
                        <CustomButton to={linkTo}>Book a call</CustomButton>
                    </div>
                </div>
            </div>
        </div>
    );
};

Builder.registerComponent(AllRevenueSourceMatchData, {
    name: "allRevenueSourceMatchData",
    inputs: toMutable(inputs),
});
