import { StaticImage } from "gatsby-plugin-image";
import { Builder } from "@builder.io/react";
import { Image as BuilderImage } from "@builder.io/react";
import { toMutable } from "types/common";
import * as styles from "./styles.module.scss";

const inputs = [
    {
        name: "title",
        type: "text",
        defaultValue: "Frequently asked questions",
    },
    {
        name: "subtitle",
        type: "text",
        defaultValue: "Here are common questions from our prospects that might answer your needs.",
    },
    {
        name: "altText",
        type: "text",
        defaultValue: "Help Centre Hero",
    },
    {
        name: "faqs",
        type: "list",
        subFields: [
            {
                name: "icon",
                type: "file",
                allowedFileTypes: ["jpg", "png", "jpeg", "gif", "svg"],
            },
            {
                name: "question",
                type: "text",
            },
            {
                name: "answer",
                type: "text",
            },
        ],
    },
] as const;

const HelpCentreFAQ: BFC<typeof inputs> = ({ title, subtitle, altText, faqs }) => {
    return (
        <div className={styles.wrapper}>
            <div className={`${styles.firstGlow} gradient`}>
                <StaticImage src="./images/help-centre-faq-first-glow.svg" alt="" />
            </div>
            <div className={`${styles.secondGlow} gradient`}>
                <StaticImage src="./images/help-centre-faq-second-glow.svg" alt="" />
            </div>
            <div className="container">
                <h2 className={styles.title}>{title}</h2>
                <p className={styles.subtitle}>{subtitle}</p>
                <div className={styles.faqs}>
                    {faqs?.map((faq, index) => (
                        <div className={styles.faq} key={index}>
                            <div className={styles.icon}>
                                {/* @ts-ignore */}
                                <BuilderImage image={faq.icon} alt={altText} lazy />
                            </div>
                            <div>
                                <h3 className={styles.question}>{faq.question}</h3>
                                <p className={styles.answer}>{faq.answer}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

Builder.registerComponent(HelpCentreFAQ, {
    name: "helpCentreFAQ",
    inputs: toMutable(inputs),
});
