import { Gallery, Item } from "react-photoswipe-gallery";
import { Builder } from "@builder.io/react";
import { Image as BuilderImage } from "@builder.io/react";
import { BOOK_CALL_LINK } from "components/constants";
import CustomButton from "components/CustomButton";
import { toMutable } from "types/common";
import glass from "./images/glass.svg";
import realgradleft from "./images/real-grad-left.svg";
import realgradright from "./images/real-grad-right.svg";

const inputs = [
    {
        name: "title",
        type: "text",
        defaultValue: "No code, fully flexible",
        required: true,
    },
    {
        name: "subtitle",
        type: "longText",
        defaultValue:
            "Empower your AdOps team with absolute control to test, change and add: New bidders, ad units, pixels, events, video players and ad units behaviours. And also to deploy directly to the webpage. Image new ways to monetize and track elements on the page and be at the top of your game.",
    },
    {
        name: "buttonText",
        type: "text",
        defaultValue: "Book a demo",
        required: true,
    },
    {
        name: "buttonTo",
        type: "text",
        defaultValue: BOOK_CALL_LINK,
        required: true,
    },
    {
        name: "switchMagnifier",
        type: "boolean",
        defaultValue: true,
        enum: [
            {
                value: true,
                label: "Yes",
            },
            {
                value: false,
                label: "No",
            },
        ],
    },
    {
        name: "imageOrGif",
        type: "file",
        allowedFileTypes: ["jpg", "png", "jpeg", "gif", "svg"],
    },
    {
        name: "imageOrGifHover",
        type: "file",
        allowedFileTypes: ["jpg", "png", "jpeg", "gif", "svg"],
    },
    {
        name: "imageOrGifAlt",
        type: "text",
        defaultValue: "Gain Bidder Intelligence",
    },
    {
        name: "imageOrGifWidth",
        type: "number",
        defaultValue: 1338,
    },
    {
        name: "imageOrGifHeight",
        type: "number",
        defaultValue: 636,
    },
] as const;

const YieldManagerNoCode: BFC<typeof inputs> = ({
    title,
    subtitle,
    buttonTo,
    switchMagnifier,
    buttonText,
    imageOrGif,
    imageOrGifHover,
    imageOrGifAlt,
    imageOrGifWidth,
    imageOrGifHeight,
}) => {
    return (
        <div className="component-wrapper">
            <img src={realgradleft} className={`gradient left`} style={{ top: "0px" }} alt="gradient" />
            <img src={realgradright} className={`gradient right`} style={{ top: "-500px" }} alt="gradient" />
            <div className="container ">
                <div className="row align-items-center gx-5 mt-5">
                    <div className="col-lg-6 order-2 order-lg-1 ">
                        <h2>{title}</h2>
                        <p className="mb-5">{subtitle}</p>
                        <CustomButton buttonStyle="btn--yellow-red" to={buttonTo}>
                            {buttonText}
                        </CustomButton>
                    </div>
                    <div className="col-lg-6 order-1  mb-5 mb-lg-0  mt-5 mt-lg-0 ">
                        <div className="mask-container  position-relative  mt-4 mb-4 mt-lg-0 mb-lg-0">
                            {imageOrGifHover ? (
                                <img
                                    onMouseOver={e => (e.currentTarget.src = imageOrGifHover)}
                                    onMouseOut={e => (e.currentTarget.src = imageOrGif!)}
                                    src={imageOrGif}
                                    alt={imageOrGifAlt}
                                    className="img-fluid"
                                />
                            ) : (
                                //@ts-ignore
                                <BuilderImage image={imageOrGif} lazy sizes="100%" altText={imageOrGifAlt} />
                            )}
                            {switchMagnifier && !imageOrGifHover && (
                                <Gallery>
                                    <Item
                                        width={imageOrGifWidth}
                                        height={imageOrGifHeight}
                                        original={imageOrGif}
                                        alt={imageOrGifAlt}
                                    >
                                        {({ ref, open }) => (
                                            // @ts-expect-error
                                            <div className="image_mask" ref={ref} onClick={open}>
                                                <img src={glass} alt="zoom image" />
                                            </div>
                                        )}
                                    </Item>
                                </Gallery>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
Builder.registerComponent(YieldManagerNoCode, {
    name: "YieldManagerNoCode",
    inputs: toMutable(inputs),
});
