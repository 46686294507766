import { CustomRangeConfig } from "../../codecs/custom-range-config.codec";
import { EdaPlacementBuilder } from "../lib";
import type { PublicRawMetricConfig } from "../raw-metrics.service";
import { CalculationType, OptimizationObjective, type PublicDimensionConfig, RenderDimension } from "../types";

const RAW_METRICS = {
    actual_revenue: {},
    actual_impressions: {},
    actual_revenue_ssp_currency: {},
} as const satisfies Record<string, PublicRawMetricConfig>;
export type DemandReportRawMetrics = keyof typeof RAW_METRICS;

const CALC_IDS = ["actual_revenue", "actual_impressions", "actual_revenue_ssp_currency"] as const;
export type DemandReportMetrics = (typeof CALC_IDS)[number];

const DIMENSIONS = {
    demand_bidder: {
        label: "SSP Name",
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "none",
    },
    demand_advertiser: {
        label: "Advertiser",
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "none",
    },
    demand_currency: {
        label: "SSP Currency",
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "none",
    },
    demand_timezone: {
        label: "Timezone",
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "none",
    },
} as const satisfies Record<string, Omit<PublicDimensionConfig, "id">>;

export type DemandReportDimensionsIds = keyof typeof DIMENSIONS;

export default new EdaPlacementBuilder()
    .withCustomRange(CustomRangeConfig.date)
    .withTimeRanges(["Last 7 Days", "Last 30 Days", "This Month", "Last Month"])
    .withTimeGranularities(["by_day", "by_week"])
    .withRawMetrics(RAW_METRICS)
    .withCalculatedMetricsIds(CALC_IDS)
    .withCalculatedMetrics({
        actual_revenue: {
            label: "Actual Revenue (USD)",
            explanation: "Actual revenue represented from SSP's connections in USD",
            formula: m => m.actual_revenue,
            calculationType: CalculationType.Sum,
            objective: OptimizationObjective.NoObjective,
        },
        actual_impressions: {
            label: "Actual Impressions",
            explanation: "Actual impressions represented from SSP's connections",
            formula: m => m.actual_impressions,
            calculationType: CalculationType.Sum,
            objective: OptimizationObjective.NoObjective,
        },
        actual_revenue_ssp_currency: {
            label: "Actual Revenue (SSP Currency)",
            explanation: "Actual revenue represented from SSP's connections in SSP currency",
            formula: m => m.actual_revenue_ssp_currency,
            calculationType: CalculationType.Sum,
            objective: OptimizationObjective.NoObjective,
        },
    })
    .withInitialMetrics(["actual_impressions", "actual_revenue", "actual_revenue_ssp_currency"])
    .withMetricTable({
        tabMeta: {
            overall: { name: "Overall", explanation: "SSP connections demand metrics" },
        },
        columnNames: {
            revenue: "Revenue (USD)",
            impressions: "Impressions",
            revenueSSPCurrency: "Revenue (SSP Currency)",
        },
        tableMapping: {
            overall: {
                revenue: ["actual_revenue"],
                impressions: ["actual_impressions"],
                revenueSSPCurrency: ["actual_revenue_ssp_currency"],
            },
        },
    })
    .withDimensions(DIMENSIONS);
