import type { FC } from "react";
import type { PageProps } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import AyInTheNews from "components/ayInTheNews/ayInTheNews.builder";
import AyBlogNewPosts from "components/blocks/AyBlogNewPosts";
import Ebooks from "components/blocks/Ebooks";
import HitBlogPosts from "components/blocks/HitBlogPosts";
import Podcasts from "components/blocks/Podcasts";
import ProductUpdates from "components/blocks/ProductUpdates";
import Videos from "components/blocks/Videos";
import CustomerStoriesBlog from "components/CustomerStoriesBlog";
import Layout from "components/Layout";
import OtherPosts from "components/OtherPosts";
import ProductUpdatesPosts from "components/ProductUpdatesPosts";
import Seo from "components/seo";
import SubscribeToAyNewsletters from "components/SubscribeToAyNewsletters";
import * as styles from "./blog.module.scss";

const IndexPage = () => {
    const blobClasses = ["d-none", "d-xxl-block", styles.blur_blob];
    return (
        <Layout bg="light" contentType="listing-page">
            <div className={styles.content_wrapper}>
                <div className={[...blobClasses, styles.blob_left_1].join(" ")}>
                    <StaticImage src="images/blob_left_1.png" alt="" />
                </div>
                <div className={[...blobClasses, styles.blob_left_2].join(" ")}>
                    <StaticImage src="images/blob_left_2.png" alt="" />
                </div>
                <div className={[...blobClasses, styles.blob_left_3].join(" ")}>
                    <StaticImage src="images/blob_left_3.png" alt="" />
                </div>
                <div className={[...blobClasses, styles.blob_left_4].join(" ")}>
                    <StaticImage src="images/blob_left_4.png" alt="" />
                </div>
                <div className={[...blobClasses, styles.blob_right_1].join(" ")}>
                    <StaticImage src="images/blob_right_1.png" alt="" />
                </div>
                <div className={[...blobClasses, styles.blob_right_2].join(" ")}>
                    <StaticImage src="images/blob_right_2.png" alt="" />
                </div>
                <div className={[...blobClasses, styles.blob_right_3].join(" ")}>
                    <StaticImage src="images/blob_right_3.png" alt="" />
                </div>
                <div className={[...blobClasses, styles.blob_right_4].join(" ")}>
                    <StaticImage src="images/blob_right_4.png" alt="" />
                </div>
                <AyBlogNewPosts />
                <HitBlogPosts />
                <ProductUpdatesPosts />
                <OtherPosts />
                <SubscribeToAyNewsletters />
                <CustomerStoriesBlog />
                <Ebooks />
                <Podcasts />
                <Videos />
                <AyInTheNews title="AY in the News" buttonText="All News" linkTo="/news/all" />
                <ProductUpdates />
            </div>
        </Layout>
    );
};

export const Head: FC<PageProps> = ({ location }) => {
    return (
        <Seo title="Blog" titleSuffixed canonical={location.pathname}>
            <script type="application/ld+json">
                {`
          {
            "@context": "https://schema.org",
            "@type": "WebPage",
            "@id": "${location.pathname}",
            "url": "${location.pathname}",
            "name": "blog",
            "datePublished": "2023-01-16T23:37:53+00:00",
            "dateModified": "2023-03-21T16:10:45+00:00",
            "description": "Get the coolest, most insightful and latest digital advertising ecosystem delivered to your inbox each month.",
            "inLanguage": "en-US",
            "isPartOf": {
              "@type": "WebSite",
              "@id": "https://www.assertiveyield.com/#website",
              "url": "https://www.assertiveyield.com/",
              "name": "Assertive Yield",
              "description": "Investment Management Solutions",
              "inLanguage": "en-US",
            "publisher": {
              "@type": "Organization",
              "@id": "https://www.assertiveyield.com/#organization",
              "name": "Assertive Yield",
              "url": "https://www.assertiveyield.com/",
              "sameAs": [
                "https://www.youtube.com/@assertiveyield",
                "https://www.linkedin.com/company/assertive-yield/"
              ],
              "logo": {
                "@type": "ImageObject",
                "@id": "https://www.assertiveyield.com/#/schema/logo/image/",
                "inLanguage": "en-US",
                "url": "https://assertiveyield.com/img/og-image.png",
                "contentUrl": "https://assertiveyield.com/img/og-image.png",
                "width": 479,
                "height": 189,
                "caption": "Assertive Yield"
              },
              "image": {
                "@type": "ImageObject",
                "@id": "https://www.assertiveyield.com/#/schema/logo/image/",
                "inLanguage": "en-US",
                "URL": "https://assertiveyield.com/img/og-image.png",
                "contentUrl": "https://assertiveyield.com/img/og-image.png",
                "width": 479,
                "height": 189,
                "caption": "Assertive Yield"
              },
              "potentialAction": {
                "@type": "SearchAction",
                "target": {
                  "@type": "EntryPoint",
                  "urlTemplate": "https://www.assertiveyield.com/?s={search_term_string}"
                },
                "query-input": {
                  "@type": "PropertyValueSpecification",
                  "valueRequired": "http://schema.org/True",
                  "valueName": "search_term_string"
                }
              }
            }
          },
          "about": {
            "@type": "Organization",
            "@id": "https://www.assertiveyield.com/#organization",
            "name": "Assertive Yield",
            "url": "https://www.assertiveyield.com/",
            "sameAs": [
              "https://www.youtube.com/@assertiveyield",
              "https://www.linkedin.com/company/assertive-yield/"
            ],
            "logo": {
              "@type": "ImageObject",
              "@id": "https://www.assertiveyield.com/#/schema/logo/image/",
              "inLanguage": "en-US",
              "url": "https://assertiveyield.com/img/og-image.png",
              "contentUrl": "https://assertiveyield.com/img/og-image.png",
              "width": 479,
              "height": 189,
              "caption": "Assertive Yield"
            }
        },
          "breadcrumb": {
            "@type": "BreadcrumbList",
            "@id": "https://www.assertiveyield.com/#breadcrumb",
            "itemListElement": [
              {
                "@type": "ListItem",
                "position": 1,
                "name": "Assertive Yield",
                "item": {
                  "@id": "https://www.assertiveyield.com/",
                  "@type": "Thing"
                }
              },
              {
                "@type": "ListItem",
                "position": 2,
                "name": "Blog"
              }
            ]
          },
          "potentialAction": {
            "@type": "ReadAction",
            "target": {
              "@type": "EntryPoint",
              "urlTemplate": "https://www.assertiveyield.com${location.pathname}"
            }
          }
        }
        `}
            </script>
        </Seo>
    );
};
export default IndexPage;
