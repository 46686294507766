import { Gallery, Item } from "react-photoswipe-gallery";
import { StaticImage } from "gatsby-plugin-image";
import { Builder } from "@builder.io/react";
import { Image as BuilderImage } from "@builder.io/react";
import { toMutable } from "types/common";
import glass from "./images/glass.svg";

const inputs = [
    {
        name: "title",
        type: "text",
        defaultValue: "Spend Attribution",
    },
    {
        name: "description",
        type: "longText",
        defaultValue:
            "Collect data from different dimensions across multiple spend partners in one place. We know that different providers tend to use different namings, and with a little bit of work with UTM paramaters, you can have them all set under one space.",
    },
    {
        name: "switchMagnifier",
        type: "boolean",
        defaultValue: true,
        enum: [
            {
                value: true,
                label: "Yes",
            },
            {
                value: false,
                label: "No",
            },
        ],
    },
    {
        name: "imageOrGif",
        type: "file",
        allowedFileTypes: ["jpg", "png", "jpeg", "gif", "svg"],
    },
    {
        name: "imageOrGifHover",
        type: "file",
        allowedFileTypes: ["jpg", "png", "jpeg", "gif", "svg"],
    },
    {
        name: "imageOrGifAlt",
        type: "text",
        defaultValue: "Gain Bidder Intelligence",
    },
    {
        name: "imageOrGifWidth",
        type: "number",
        defaultValue: 1338,
    },
    {
        name: "imageOrGifHeight",
        type: "number",
        defaultValue: 636,
    },
] as const;

const trafficAcquisitionSpendAttribution: BFC<typeof inputs> = ({
    title,
    description,
    switchMagnifier,
    imageOrGif,
    imageOrGifHover,
    imageOrGifAlt,
    imageOrGifWidth,
    imageOrGifHeight,
}) => {
    return (
        <div className="component-wrapper">
            <div className="gradient left" style={{ top: "-500px", left: "0px" }}>
                <StaticImage src="./images/left.png" alt="" />
            </div>
            <div className="container">
                <div className="row align-items-center justify-content-center gx-5">
                    <div className="col-12 col-lg-9 order-2 order-lg-1   text-lg-center">
                        <h2>{title}</h2>
                        <p>{description}</p>
                    </div>
                    <div className="col-12 order-1 order-lg-2 mb-4 mb-lg-0">
                        <div className="mask-container  position-relative ">
                            {imageOrGifHover ? (
                                <img
                                    onMouseOver={e => (e.currentTarget.src = imageOrGifHover)}
                                    onMouseOut={e => (e.currentTarget.src = imageOrGif!)}
                                    src={imageOrGif}
                                    alt={imageOrGifAlt}
                                    className="img-fluid"
                                />
                            ) : (
                                //@ts-ignore
                                <BuilderImage image={imageOrGif} lazy sizes="100%" altText={imageOrGifAlt} />
                            )}
                            {switchMagnifier && !imageOrGifHover && (
                                <Gallery>
                                    <Item
                                        width={imageOrGifWidth}
                                        height={imageOrGifHeight}
                                        original={imageOrGif}
                                        alt={imageOrGifAlt}
                                    >
                                        {({ ref, open }) => (
                                            // @ts-expect-error
                                            <div className="image_mask" ref={ref} onClick={open}>
                                                <img src={glass} alt="zoom image" />
                                            </div>
                                        )}
                                    </Item>
                                </Gallery>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
Builder.registerComponent(trafficAcquisitionSpendAttribution, {
    name: "trafficAcquisitionSpendAttribution",
    inputs: toMutable(inputs),
});
