export const parsedPrettyJsMetrics = {
    "web": {
        "acquisition_cost": "acquisition_cost / 1000000",
        "acquisition_cost_measurable_session_starts": "acquisition_cost_measurable_session_starts",
        "active_pageView_time_minutes": "sum_pageView_activeEngagementTime / pageViews / 1000 / 60",
        "active_session_time_minutes": "sum_pageView_activeEngagementTime / sessions / 1000 / 60",
        "ads_per_pageView": "impressions / pageViews",
        "ads_per_session": "impressions / sessions",
        "avg_decoded_body_size": "nav_decoded_body_size / pageViews",
        "avg_dom_completion_time": "nav_dom_content_loading_time / pageViews",
        "avg_dom_processing_time": "nav_dom_processing_time / pageViews",
        "avg_encoded_body_size": "nav_encoded_body_size / pageViews",
        "avg_network_time": "nav_network_time / pageViews",
        "avg_on_load_time": "nav_unload_event_time / pageViews",
        "avg_page_load_time": "nav_dom_content_loading_time / pageViews",
        "avg_server_time": "nav_server_time / pageViews",
        "avg_transfer_size": "nav_transfer_size / pageViews",
        "avg_transfer_time": "nav_transfer_time / pageViews",
        "bounceRate": "count_low_engagement / count_event_end",
        "errors": "errors",
        "errors_per_pageView": "errors / pageViews",
        "errors_per_session": "errors / sessions",
        "impressions": "impressions",
        "mean_scrollY": "scrollY / pageViews",
        "pageView_starts": "pageView_starts",
        "pageViews": "pageViews",
        "pageViews_per_session": "pageViews / sessions",
        "pageViews_without_ads_ratio": "(pageViews_ends - pageView_with_ads) / pageViews_ends",
        "session_starts": "session_starts",
        "sessions": "sessions",
        "sessions_without_ads_ratio": "(sessions_ends - session_with_ads) / sessions_ends",
        "vitals_cls": "vitals_cls_sum / vitals_cls_measurable",
        "vitals_cls_bad_rate": "vitals_cls_bad / vitals_cls_measurable",
        "vitals_cls_good_rate": "vitals_cls_good / vitals_cls_measurable",
        "vitals_cls_medium_rate": "vitals_cls_medium / vitals_cls_measurable",
        "vitals_fcp": "vitals_fcp_sum / vitals_fcp_measurable",
        "vitals_fcp_bad_rate": "vitals_fcp_bad / vitals_fcp_measurable",
        "vitals_fcp_good_rate": "vitals_fcp_good / vitals_fcp_measurable",
        "vitals_fcp_medium_rate": "vitals_fcp_medium / vitals_fcp_measurable",
        "vitals_fid": "vitals_fid_sum / vitals_fid_measurable",
        "vitals_fid_bad_rate": "vitals_fid_bad / vitals_fid_measurable",
        "vitals_fid_good_rate": "vitals_fid_good / vitals_fid_measurable",
        "vitals_fid_medium_rate": "vitals_fid_medium / vitals_fid_measurable",
        "vitals_inp": "vitals_inp_sum / vitals_inp_measurable",
        "vitals_inp_bad_rate": "vitals_inp_bad / vitals_inp_measurable",
        "vitals_inp_good_rate": "vitals_inp_good / vitals_inp_measurable",
        "vitals_inp_medium_rate": "vitals_inp_medium / vitals_inp_measurable",
        "vitals_lcp": "vitals_lcp_sum / vitals_lcp_measurable",
        "vitals_lcp_bad_rate": "vitals_lcp_bad / vitals_lcp_measurable",
        "vitals_lcp_good_rate": "vitals_lcp_good / vitals_lcp_measurable",
        "vitals_lcp_medium_rate": "vitals_lcp_medium / vitals_lcp_measurable",
        "vitals_ttfb": "vitals_ttfb_sum / vitals_ttfb_measurable",
        "vitals_ttfb_bad_rate": "vitals_ttfb_bad / vitals_ttfb_measurable",
        "vitals_ttfb_good_rate": "vitals_ttfb_good / vitals_ttfb_measurable",
        "vitals_ttfb_medium_rate": "vitals_ttfb_medium / vitals_ttfb_measurable"
    },
    "error_analytics": {
        "errors": "errors",
        "fatal_prebid_error_count": "fatal_prebid_error_count",
        "same_bid_error_count": "same_bid_error_count",
        "sessions": "sessions"
    },
    "yield": {
        "acquisition_cost": "acquisition_cost / 1000000",
        "acquisition_cost_measurable_session_starts": "acquisition_cost_measurable_session_starts",
        "ad_unfilled": "ad_unfilled_count",
        "ads_per_pageView": "impressions / pageViews",
        "ads_per_pageViewExact": "impressions / pageViewsExact",
        "ads_per_session": "impressions / sessions",
        "adSense_impressions": "adSense_impressions",
        "adSense_pageView_rpm": "(adSense_revenue / 1000000 / pageViews) * 1000",
        "adSense_revenue": "adSense_revenue / 1000000",
        "adSense_rpm": "(adSense_revenue / 1000000 / adSense_impressions) * 1000",
        "adSense_session_rpm": "(adSense_revenue / 1000000 / sessions) * 1000",
        "adSense_winRate": "adSense_impressions / impressions",
        "aps_impressions": "aps_impressions",
        "aps_pageView_rpm": "(aps_revenue / 1000000 / pageViews) * 1000",
        "aps_revenue": "aps_revenue / 1000000",
        "aps_rpm": "(aps_revenue / 1000000 / aps_impressions) * 1000",
        "aps_session_rpm": "(aps_revenue / 1000000 / sessions) * 1000",
        "aps_winRate": "aps_impressions / impressions",
        "ayMediation_impressions": "ayMediation_impressions",
        "ayMediation_pageView_rpm": "(ayMediation_revenue / pageViews) * 1000",
        "ayMediation_revenue": "ayMediation_revenue",
        "ayMediation_rpm": "(ayMediation_revenue / ayMediation_impressions) * 1000",
        "ayMediation_session_rpm": "(ayMediation_revenue / sessions) * 1000",
        "ayMediation_winRate": "ayMediation_impressions / impressions",
        "blocked_impressions": "blocked_impressions",
        "blocked_impressions_rate": "blocked_impressions / impressions",
        "cbr": "clicksBounced / clicks",
        "clicks": "clicks",
        "clicks_bounce_session_rate": "sessions_with_bounced_clicks / sessions",
        "clr": "(clicks - clicksReturned) / clicks",
        "content_recommendation_impressions": "outbrain_impressions + taboola_impressions + yahooGemini_impressions",
        "content_recommendation_pageView_rpm": "((outbrain_revenue / 1000000 + taboola_revenue / 1000000 + yahooGemini_revenue / 1000000) / pageViews) * 1000",
        "content_recommendation_revenue": "outbrain_revenue / 1000000 + taboola_revenue / 1000000 + yahooGemini_revenue / 1000000",
        "content_recommendation_rpm": "((outbrain_revenue / 1000000 + taboola_revenue / 1000000 + yahooGemini_revenue / 1000000) / (outbrain_impressions + taboola_impressions + yahooGemini_impressions)) * 1000",
        "content_recommendation_session_rpm": "((outbrain_revenue / 1000000 + taboola_revenue / 1000000 + yahooGemini_revenue / 1000000) / sessions) * 1000",
        "content_recommendation_winRate": "(outbrain_impressions + taboola_impressions + yahooGemini_impressions) / impressions",
        "ctr": "(clicks / impressions_filled) * 100",
        "direct_impressions": "direct_impressions",
        "direct_pageView_rpm": "(direct_revenue / 1000000 / pageViews) * 1000",
        "direct_pageView_valueRpm": "(direct_revenue / 1000000 / pageViews) * 1000 * (viewable / viewable_measurable_impressions)",
        "direct_revenue": "direct_revenue / 1000000",
        "direct_rpm": "(direct_revenue / 1000000 / direct_impressions) * 1000",
        "direct_session_rpm": "(direct_revenue / 1000000 / sessions) * 1000",
        "direct_session_valueRpm": "(direct_revenue / 1000000 / sessions) * 1000 * (viewable / viewable_measurable_impressions)",
        "direct_valueRpm": "(direct_revenue / 1000000 / direct_impressions) * 1000 * (viewable / viewable_measurable_impressions)",
        "direct_winRate": "direct_impressions / impressions",
        "dynamicAllocation_confirmed_clicks_rate": "dynamicAllocation_impressions === 0 ? null : dynamicAllocation_confirmed_clicks / dynamicAllocation_impressions",
        "dynamicAllocation_impressions": "dynamicAllocation_impressions",
        "dynamicAllocation_pageView_rpm": "(dynamicAllocation_revenue / 1000000 / pageViews) * 1000",
        "dynamicAllocation_pageView_valueRpm": "(dynamicAllocation_revenue / 1000000 / pageViews) * 1000 * (viewable / viewable_measurable_impressions)",
        "dynamicAllocation_pageViewExact_rpm": "(dynamicAllocation_revenue / 1000000 / pageViewsExact) * 1000",
        "dynamicAllocation_predicted_impressions": "dynamicAllocation_predicted_impressions",
        "dynamicAllocation_predicted_impressions_error": "dynamicAllocation_impressions - dynamicAllocation_predicted_impressions",
        "dynamicAllocation_predicted_revenue": "dynamicAllocation_predicted_revenue / 1000000 / 1000",
        "dynamicAllocation_predicted_revenue_error": "dynamicAllocation_revenue / 1000000 === 0 ? null : Math.abs( dynamicAllocation_revenue_with_forecast_client / 1000000 - dynamicAllocation_predicted_revenue / 1000000 / 1000, )",
        "dynamicAllocation_predicted_revenue_error_server": "dynamicAllocation_revenue / 1000000 === 0 ? null : Math.abs( dynamicAllocation_revenue_with_forecast / 1000000 - dynamicAllocation_predicted_revenue_server / 1000000 / 1000, )",
        "dynamicAllocation_predicted_revenue_mae": "dynamicAllocation_predicted_revenue_abs_difference === 0 ? null : dynamicAllocation_predicted_revenue_abs_difference / 1000 / dynamicAllocation_impressions",
        "dynamicAllocation_predicted_revenue_mae_server": "dynamicAllocation_predicted_revenue_server_abs_difference === 0 ? null : dynamicAllocation_predicted_revenue_server_abs_difference / 1000 / dynamicAllocation_impressions",
        "dynamicAllocation_predicted_revenue_relative_error": "dynamicAllocation_revenue / 1000000 === 0 ? null : Math.abs( dynamicAllocation_predicted_revenue / 1000000 / 1000 - dynamicAllocation_revenue_with_forecast_client / 1000000, ) / (dynamicAllocation_revenue_with_forecast_client / 1000000)",
        "dynamicAllocation_predicted_revenue_relative_error_server": "dynamicAllocation_revenue_with_forecast / 1000000 === 0 ? null : Math.abs( dynamicAllocation_predicted_revenue_server / 1000000 / 1000 - dynamicAllocation_revenue_with_forecast / 1000000, ) / (dynamicAllocation_revenue_with_forecast / 1000000)",
        "dynamicAllocation_predicted_revenue_rmse": "dynamicAllocation_predicted_revenue_residual === 0 ? null : Math.sqrt( dynamicAllocation_predicted_revenue_residual / 1000000 / dynamicAllocation_impressions, )",
        "dynamicAllocation_predicted_revenue_rmse_server": "dynamicAllocation_predicted_revenue_server_residual === 0 ? null : Math.sqrt( dynamicAllocation_predicted_revenue_server_residual / 1000000 / dynamicAllocation_impressions, )",
        "dynamicAllocation_predicted_revenue_server": "dynamicAllocation_predicted_revenue_server / 1000000 / 1000",
        "dynamicAllocation_predicted_rpm": "(dynamicAllocation_predicted_revenue / 1000000 / 1000 / dynamicAllocation_impressions) * 1000",
        "dynamicAllocation_predicted_rpm_server": "(dynamicAllocation_predicted_revenue_server / 1000000 / 1000 / dynamicAllocation_impressions) * 1000",
        "dynamicAllocation_revenue": "dynamicAllocation_revenue / 1000000",
        "dynamicAllocation_revenue_with_forecast": "dynamicAllocation_revenue_with_forecast / 1000000",
        "dynamicAllocation_revenue_with_forecast_client": "dynamicAllocation_revenue_with_forecast_client / 1000000",
        "dynamicAllocation_rpm": "(dynamicAllocation_revenue / 1000000 / dynamicAllocation_impressions) * 1000",
        "dynamicAllocation_session_rpm": "(dynamicAllocation_revenue / 1000000 / sessions) * 1000",
        "dynamicAllocation_session_rpm_with_forecast": "(dynamicAllocation_revenue_with_forecast / 1000000 / sessions) * 1000",
        "dynamicAllocation_session_valueRpm": "(dynamicAllocation_revenue / 1000000 / sessions) * 1000 * (viewable / viewable_measurable_impressions)",
        "dynamicAllocation_valueRpm": "(dynamicAllocation_revenue / 1000000 / dynamicAllocation_impressions) * 1000 * (viewable / viewable_measurable_impressions)",
        "dynamicAllocation_winRate": "dynamicAllocation_impressions / impressions",
        "floor_empirical_revenue_ratio": "floor_empirical_revenue / (prebid_won_revenue / 1000000 / 1000 + dynamicAllocation_revenue / 1000000 + direct_revenue / 1000000 + (outbrain_revenue / 1000000 + taboola_revenue / 1000000 + yahooGemini_revenue / 1000000) + adSense_revenue / 1000000 + aps_revenue / 1000000 + nativo_revenue)",
        "floor_impressions": "floor_measurable_impressions",
        "floor_rpm": "floor / 1000000 / floor_measurable_impressions",
        "givt_rate": "givt_impressions / ivt_measurable_impressions",
        "has_dfp_amazonBid_count": "has_dfp_amazonBid_count",
        "has_dfp_hash_count": "has_dfp_hash_count",
        "impressions": "impressions",
        "impressions_filled": "impressions_filled",
        "impressions_filled_rate": "impressions_filled / impressions",
        "impressions_unfilled": "impressions_unfilled",
        "impressions_unfilled_n_house": "impressions_lineitem_house + impressions_unfilled",
        "impressions_unfilled_n_house_rate": "(impressions_lineitem_house + impressions_unfilled) / impressions",
        "impressions_unfilled_rate": "impressions_unfilled / impressions",
        "ivt_rate": "(givt_impressions + sivt_impressions) / ivt_measurable_impressions",
        "lineItem_revenue": "lineItem_revenue / 1000000",
        "miss_click_impression_pct": "(clicksBounced / impressions) * 100",
        "nativo_impressions": "nativo_impressions",
        "nativo_pageView_rpm": "(nativo_revenue / pageViews) * 1000",
        "nativo_revenue": "nativo_revenue",
        "nativo_rpm": "(nativo_revenue / nativo_impressions) * 1000",
        "nativo_session_rpm": "(nativo_revenue / sessions) * 1000",
        "nativo_winRate": "nativo_impressions / impressions",
        "pageView_rpm": "((prebid_won_revenue / 1000000 / 1000 + dynamicAllocation_revenue / 1000000 + direct_revenue / 1000000 + (outbrain_revenue / 1000000 + taboola_revenue / 1000000 + yahooGemini_revenue / 1000000) + adSense_revenue / 1000000 + aps_revenue / 1000000 + nativo_revenue) / pageViews) * 1000",
        "pageView_starts": "pageView_starts",
        "pageView_valueRpm": "((prebid_won_revenue / 1000000 / 1000 + dynamicAllocation_revenue / 1000000 + direct_revenue / 1000000 + (outbrain_revenue / 1000000 + taboola_revenue / 1000000 + yahooGemini_revenue / 1000000) + adSense_revenue / 1000000 + aps_revenue / 1000000 + nativo_revenue) / pageViews) * 1000 * (viewable / viewable_measurable_impressions)",
        "pageViews": "pageViews",
        "pageViews_per_session": "pageViews / sessions",
        "pageViewsExact": "pageViewsExact",
        "pageViewsExact_per_session": "pageViewsExact / sessions",
        "prebid_cache_rate": "prebid_cached_count / prebid_won_impressions",
        "prebid_incremental_value": "prebid_incremental_value / 1000000 / 1000",
        "prebid_incremental_value_rpm": "(prebid_incremental_value / 1000000 / 1000 / prebid_won_impressions) * 1000",
        "prebid_lost_impressions": "prebid_lost_impressions",
        "prebid_lost_opportunity_rate": "ad_unfilled_with_prebid_bids_count / ad_unfilled_count",
        "prebid_lost_pageView_rpm": "(prebid_lost_revenue / 1000000 / 1000 / pageViews) * 1000",
        "prebid_lost_pageView_valueRpm": "(prebid_lost_revenue / 1000000 / 1000 / pageViews) * 1000 * (viewable / viewable_measurable_impressions)",
        "prebid_lost_revenue": "prebid_lost_revenue / 1000000 / 1000",
        "prebid_lost_rpm": "(prebid_lost_revenue / 1000000 / 1000 / prebid_lost_impressions) * 1000",
        "prebid_lost_session_rpm": "(prebid_lost_revenue / 1000000 / 1000 / sessions) * 1000",
        "prebid_lost_session_valueRpm": "(prebid_lost_revenue / 1000000 / 1000 / sessions) * 1000 * (viewable / viewable_measurable_impressions)",
        "prebid_lost_valueRpm": "(prebid_lost_revenue / 1000000 / 1000 / prebid_lost_impressions) * 1000 * (viewable / viewable_measurable_impressions)",
        "prebid_pageView_rpm": "(prebid_revenue / 1000000 / 1000 / pageViews) * 1000",
        "prebid_pageView_valueRpm": "(prebid_revenue / 1000000 / 1000 / pageViews) * 1000 * (viewable / viewable_measurable_impressions)",
        "prebid_revenue": "prebid_revenue / 1000000 / 1000",
        "prebid_rpm": "(prebid_revenue / 1000000 / 1000 / impressions) * 1000",
        "prebid_session_rpm": "(prebid_revenue / 1000000 / 1000 / sessions) * 1000",
        "prebid_session_valueRpm": "(prebid_revenue / 1000000 / 1000 / sessions) * 1000 * (viewable / viewable_measurable_impressions)",
        "prebid_user_id_providers_avg": "prebid_user_id_providers_sum_uses / impressions",
        "prebid_user_id_providers_zero_usages": "prebid_user_id_providers_zero_usages",
        "prebid_user_id_providers_zero_usages_rate": "prebid_user_id_providers_zero_usages / impressions",
        "prebid_valueRpm": "(prebid_revenue / 1000000 / 1000 / impressions) * 1000 * (viewable / viewable_measurable_impressions)",
        "prebid_winRate": "prebid_won_impressions / impressions",
        "prebid_won_impressions": "prebid_won_impressions",
        "prebid_won_pageView_rpm": "(prebid_won_revenue / 1000000 / 1000 / pageViews) * 1000",
        "prebid_won_pageView_valueRpm": "(prebid_won_revenue / 1000000 / 1000 / pageViews) * 1000 * (viewable / viewable_measurable_impressions)",
        "prebid_won_pageViewExact_rpm": "(prebid_won_revenue / 1000000 / 1000 / pageViewsExact) * 1000",
        "prebid_won_revenue": "prebid_won_revenue / 1000000 / 1000",
        "prebid_won_rpm": "(prebid_won_revenue / 1000000 / 1000 / prebid_won_impressions) * 1000",
        "prebid_won_session_rpm": "(prebid_won_revenue / 1000000 / 1000 / sessions) * 1000",
        "prebid_won_session_valueRpm": "(prebid_won_revenue / 1000000 / 1000 / sessions) * 1000 * (viewable / viewable_measurable_impressions)",
        "prebid_won_valueRpm": "(prebid_won_revenue / 1000000 / 1000 / prebid_won_impressions) * 1000 * (viewable / viewable_measurable_impressions)",
        "programmatic_impressions": "prebid_won_impressions + dynamicAllocation_impressions + adSense_impressions + aps_impressions + (outbrain_impressions + taboola_impressions + yahooGemini_impressions)",
        "programmatic_pageView_rpm": "((prebid_won_revenue / 1000000 / 1000 + dynamicAllocation_revenue / 1000000 + adSense_revenue / 1000000 + aps_revenue / 1000000 + (outbrain_revenue / 1000000 + taboola_revenue / 1000000 + yahooGemini_revenue / 1000000)) / pageViews) * 1000",
        "programmatic_pageView_valueRpm": "((prebid_won_revenue / 1000000 / 1000 + dynamicAllocation_revenue / 1000000 + adSense_revenue / 1000000 + aps_revenue / 1000000 + (outbrain_revenue / 1000000 + taboola_revenue / 1000000 + yahooGemini_revenue / 1000000)) / pageViews) * 1000 * (viewable / viewable_measurable_impressions)",
        "programmatic_revenue": "prebid_won_revenue / 1000000 / 1000 + dynamicAllocation_revenue / 1000000 + adSense_revenue / 1000000 + aps_revenue / 1000000 + (outbrain_revenue / 1000000 + taboola_revenue / 1000000 + yahooGemini_revenue / 1000000)",
        "programmatic_revenue_with_forecast": "prebid_won_revenue / 1000000 / 1000 + dynamicAllocation_revenue_with_forecast / 1000000 + adSense_revenue / 1000000 + aps_revenue / 1000000 + (outbrain_revenue / 1000000 + taboola_revenue / 1000000 + yahooGemini_revenue / 1000000)",
        "programmatic_rpm": "((prebid_won_revenue / 1000000 / 1000 + dynamicAllocation_revenue / 1000000 + adSense_revenue / 1000000 + aps_revenue / 1000000 + (outbrain_revenue / 1000000 + taboola_revenue / 1000000 + yahooGemini_revenue / 1000000)) / (prebid_won_impressions + dynamicAllocation_impressions + adSense_impressions + aps_impressions + (outbrain_impressions + taboola_impressions + yahooGemini_impressions))) * 1000",
        "programmatic_session_rpm": "((prebid_won_revenue / 1000000 / 1000 + dynamicAllocation_revenue / 1000000 + adSense_revenue / 1000000 + aps_revenue / 1000000 + (outbrain_revenue / 1000000 + taboola_revenue / 1000000 + yahooGemini_revenue / 1000000)) / sessions) * 1000",
        "programmatic_session_rpm_with_forecast": "((prebid_won_revenue / 1000000 / 1000 + dynamicAllocation_revenue_with_forecast / 1000000 + adSense_revenue / 1000000 + aps_revenue / 1000000 + (outbrain_revenue / 1000000 + taboola_revenue / 1000000 + yahooGemini_revenue / 1000000)) / sessions) * 1000",
        "programmatic_session_valueRpm": "((prebid_won_revenue / 1000000 / 1000 + dynamicAllocation_revenue / 1000000 + adSense_revenue / 1000000 + aps_revenue / 1000000 + (outbrain_revenue / 1000000 + taboola_revenue / 1000000 + yahooGemini_revenue / 1000000)) / sessions) * 1000 * (viewable / viewable_measurable_impressions)",
        "programmatic_valueRpm": "((prebid_won_revenue / 1000000 / 1000 + dynamicAllocation_revenue / 1000000 + adSense_revenue / 1000000 + aps_revenue / 1000000 + (outbrain_revenue / 1000000 + taboola_revenue / 1000000 + yahooGemini_revenue / 1000000)) / (prebid_won_impressions + dynamicAllocation_impressions + adSense_impressions + aps_impressions + (outbrain_impressions + taboola_impressions + yahooGemini_impressions))) * 1000 * (viewable / viewable_measurable_impressions)",
        "programmatic_winRate": "(prebid_won_impressions + dynamicAllocation_impressions + adSense_impressions + aps_impressions + (outbrain_impressions + taboola_impressions + yahooGemini_impressions)) / impressions",
        "revenue": "prebid_won_revenue / 1000000 / 1000 + dynamicAllocation_revenue / 1000000 + direct_revenue / 1000000 + (outbrain_revenue / 1000000 + taboola_revenue / 1000000 + yahooGemini_revenue / 1000000) + adSense_revenue / 1000000 + aps_revenue / 1000000 + nativo_revenue",
        "revenue_with_forecast": "prebid_won_revenue / 1000000 / 1000 + dynamicAllocation_revenue_with_forecast / 1000000 + direct_revenue / 1000000 + (outbrain_revenue / 1000000 + taboola_revenue / 1000000 + yahooGemini_revenue / 1000000) + adSense_revenue / 1000000 + aps_revenue / 1000000 + nativo_revenue",
        "rpm": "((prebid_won_revenue / 1000000 / 1000 + dynamicAllocation_revenue / 1000000 + direct_revenue / 1000000 + (outbrain_revenue / 1000000 + taboola_revenue / 1000000 + yahooGemini_revenue / 1000000) + adSense_revenue / 1000000 + aps_revenue / 1000000 + nativo_revenue) / impressions) * 1000",
        "rpm_with_forecast": "((prebid_won_revenue / 1000000 / 1000 + dynamicAllocation_revenue_with_forecast / 1000000 + direct_revenue / 1000000 + (outbrain_revenue / 1000000 + taboola_revenue / 1000000 + yahooGemini_revenue / 1000000) + adSense_revenue / 1000000 + aps_revenue / 1000000 + nativo_revenue) / impressions) * 1000",
        "session_rpm": "((prebid_won_revenue / 1000000 / 1000 + dynamicAllocation_revenue / 1000000 + direct_revenue / 1000000 + (outbrain_revenue / 1000000 + taboola_revenue / 1000000 + yahooGemini_revenue / 1000000) + adSense_revenue / 1000000 + aps_revenue / 1000000 + nativo_revenue) / sessions) * 1000",
        "session_rpm_with_forecast": "((prebid_won_revenue / 1000000 / 1000 + dynamicAllocation_revenue_with_forecast / 1000000 + direct_revenue / 1000000 + (outbrain_revenue / 1000000 + taboola_revenue / 1000000 + yahooGemini_revenue / 1000000) + adSense_revenue / 1000000 + aps_revenue / 1000000 + nativo_revenue) / sessions) * 1000",
        "session_start_rpm": "((prebid_won_revenue / 1000000 / 1000 + dynamicAllocation_revenue / 1000000 + direct_revenue / 1000000 + (outbrain_revenue / 1000000 + taboola_revenue / 1000000 + yahooGemini_revenue / 1000000) + adSense_revenue / 1000000 + aps_revenue / 1000000 + nativo_revenue) / session_starts) * 1000",
        "session_start_rpm_with_forecast": "((prebid_won_revenue / 1000000 / 1000 + dynamicAllocation_revenue_with_forecast / 1000000 + direct_revenue / 1000000 + (outbrain_revenue / 1000000 + taboola_revenue / 1000000 + yahooGemini_revenue / 1000000) + adSense_revenue / 1000000 + aps_revenue / 1000000 + nativo_revenue) / session_starts) * 1000",
        "session_starts": "session_starts",
        "session_valueRpm": "((prebid_won_revenue / 1000000 / 1000 + dynamicAllocation_revenue / 1000000 + direct_revenue / 1000000 + (outbrain_revenue / 1000000 + taboola_revenue / 1000000 + yahooGemini_revenue / 1000000) + adSense_revenue / 1000000 + aps_revenue / 1000000 + nativo_revenue) / sessions) * 1000 * (viewable / viewable_measurable_impressions)",
        "sessions": "sessions",
        "sessions_with_bounced_clicks": "sessions_with_bounced_clicks",
        "sivt_rate": "sivt_impressions / ivt_measurable_impressions",
        "uniqueExternalId": "uniqueExternalId",
        "valueCpm": "((prebid_won_revenue / 1000000 / 1000 + dynamicAllocation_revenue / 1000000 + direct_revenue / 1000000 + (outbrain_revenue / 1000000 + taboola_revenue / 1000000 + yahooGemini_revenue / 1000000) + adSense_revenue / 1000000 + aps_revenue / 1000000 + nativo_revenue) / impressions) * 1000 * (viewable / viewable_measurable_impressions)",
        "viewability": "viewable / viewable_measurable_impressions",
        "vitals_cls": "vitals_cls_sum / vitals_cls_measurable",
        "vitals_cls_bad_rate": "vitals_cls_bad / vitals_cls_measurable",
        "vitals_cls_good_rate": "vitals_cls_good / vitals_cls_measurable",
        "vitals_cls_medium_rate": "vitals_cls_medium / vitals_cls_measurable",
        "vitals_fcp": "vitals_fcp_sum / vitals_fcp_measurable",
        "vitals_fcp_bad_rate": "vitals_fcp_bad / vitals_fcp_measurable",
        "vitals_fcp_good_rate": "vitals_fcp_good / vitals_fcp_measurable",
        "vitals_fcp_medium_rate": "vitals_fcp_medium / vitals_fcp_measurable",
        "vitals_fid": "vitals_fid_sum / vitals_fid_measurable",
        "vitals_fid_bad_rate": "vitals_fid_bad / vitals_fid_measurable",
        "vitals_fid_good_rate": "vitals_fid_good / vitals_fid_measurable",
        "vitals_fid_medium_rate": "vitals_fid_medium / vitals_fid_measurable",
        "vitals_inp": "vitals_inp_sum / vitals_inp_measurable",
        "vitals_inp_bad_rate": "vitals_inp_bad / vitals_inp_measurable",
        "vitals_inp_good_rate": "vitals_inp_good / vitals_inp_measurable",
        "vitals_inp_medium_rate": "vitals_inp_medium / vitals_inp_measurable",
        "vitals_lcp": "vitals_lcp_sum / vitals_lcp_measurable",
        "vitals_lcp_bad_rate": "vitals_lcp_bad / vitals_lcp_measurable",
        "vitals_lcp_good_rate": "vitals_lcp_good / vitals_lcp_measurable",
        "vitals_lcp_medium_rate": "vitals_lcp_medium / vitals_lcp_measurable",
        "vitals_ttfb": "vitals_ttfb_sum / vitals_ttfb_measurable",
        "vitals_ttfb_bad_rate": "vitals_ttfb_bad / vitals_ttfb_measurable",
        "vitals_ttfb_good_rate": "vitals_ttfb_good / vitals_ttfb_measurable",
        "vitals_ttfb_medium_rate": "vitals_ttfb_medium / vitals_ttfb_measurable"
    },
    "prebid": {
        "prebid_report_auction_win_rpm": "(prebid_report_wins_revenue / 1000000 / 1000 / prebid_report_auctions) * 1000",
        "prebid_report_auctions": "prebid_report_auctions",
        "prebid_report_bid_cpm": "(prebid_report_bids_revenue / 1000000 / 1000 / prebid_report_bids) * 1000",
        "prebid_report_bid_position": "prebid_report_bid_position / prebid_report_bids",
        "prebid_report_bid_rate": "prebid_report_bids / prebid_report_requests",
        "prebid_report_bidDensity_auction": "prebid_report_bids / prebid_report_auctions",
        "prebid_report_bidDensity_pageView": "prebid_report_bids / prebid_report_pageViews",
        "prebid_report_bidDensity_session": "prebid_report_bids / prebid_report_sessions",
        "prebid_report_bids": "prebid_report_bids",
        "prebid_report_bids_revenue": "prebid_report_bids_revenue / 1000000 / 1000",
        "prebid_report_bidVolume_auction_cpm": "(prebid_report_bids_revenue / 1000000 / 1000 / prebid_report_auctions) * 1000",
        "prebid_report_bidVolume_pageView_cpm": "(prebid_report_bids_revenue / 1000000 / 1000 / prebid_report_pageViews) * 1000",
        "prebid_report_bidVolume_session_cpm": "(prebid_report_bids_revenue / 1000000 / 1000 / prebid_report_sessions) * 1000",
        "prebid_report_floor_cpm": "prebid_report_floor_sum / 1000000 / prebid_report_floor_non_null",
        "prebid_report_floor_rate": "prebid_report_floor_non_null / prebid_report_bids",
        "prebid_report_givt_rate": "prebid_report_givt_impressions / prebid_report_ivt_measurable_impressions",
        "prebid_report_highest_bid_rate": "prebid_report_highest_bid / prebid_report_bids",
        "prebid_report_ivt_rate": "(prebid_report_givt_impressions + prebid_report_sivt_impressions) / prebid_report_ivt_measurable_impressions",
        "prebid_report_noBid_rate": "prebid_report_noBids / prebid_report_requests",
        "prebid_report_noBids": "prebid_report_noBids",
        "prebid_report_renderedTime": "prebid_report_renderedTime / prebid_report_wins",
        "prebid_report_request_cpm": "(prebid_report_bids_revenue / 1000000 / 1000 / prebid_report_requests) * 1000",
        "prebid_report_request_win_rate": "prebid_report_wins / prebid_report_requests",
        "prebid_report_request_win_rpm": "(prebid_report_wins_revenue / 1000000 / 1000 / prebid_report_requests) * 1000",
        "prebid_report_requests": "prebid_report_requests",
        "prebid_report_requests_per_auction": "prebid_report_requests / prebid_report_auctions",
        "prebid_report_setTargetingTime": "prebid_report_setTargetingTime / prebid_report_bids",
        "prebid_report_sivt_rate": "prebid_report_sivt_impressions / prebid_report_ivt_measurable_impressions",
        "prebid_report_time_to_respond_p50": "prebid_report_time_to_respond_p50 / prebid_report_requests",
        "prebid_report_time_to_respond_p75": "prebid_report_time_to_respond_p75 / prebid_report_requests",
        "prebid_report_time_to_respond_p95": "prebid_report_time_to_respond_p95 / prebid_report_requests",
        "prebid_report_time_to_respond_p99": "prebid_report_time_to_respond_p99 / prebid_report_requests",
        "prebid_report_timeout_rate": "prebid_report_timeouts / prebid_report_requests",
        "prebid_report_timeouts": "prebid_report_timeouts",
        "prebid_report_timeToRespond": "prebid_report_timeToRespond / prebid_report_bids",
        "prebid_report_win_cpm": "(prebid_report_wins_revenue / 1000000 / 1000 / prebid_report_wins) * 1000",
        "prebid_report_win_rate": "prebid_report_wins / prebid_report_bids",
        "prebid_report_wins": "prebid_report_wins",
        "prebid_report_wins_revenue": "prebid_report_wins_revenue / 1000000 / 1000"
    },
    "traffic_shaping_server_samples_predict": {
        "bid_accuracy": "100 * ((bid_true_positive + bid_true_negative) / requests_raw)",
        "bid_cpm": "(bid_revenue / 1000 / bids_raw) * 1000",
        "bid_precision": "100 * (bid_true_positive / (bid_true_positive + bid_false_positive))",
        "bid_rate": "bids_raw / requests_raw",
        "bid_recall": "100 * (bid_true_positive / (bid_true_positive + bid_false_negative))",
        "bid_revenue": "bid_revenue / 1000",
        "bid_specificity": "100 * (bid_true_negative / (bid_true_negative + bid_false_positive))",
        "bids": "bids_raw",
        "feature_bid_cpm_cache_avg": "(feature_bid_cpm_cache_sum / 1000 / requests_raw) * 1000 * 1000",
        "feature_bid_cpm_cache_sum": "feature_bid_cpm_cache_sum / 1000",
        "feature_bid_sum_avg": "(feature_bid_sum_sum / 1000 / requests_raw) * 1000 * 1000",
        "feature_bid_sum_sum": "feature_bid_sum_sum / 1000",
        "feature_custom_num_1_avg": "feature_custom_num_1_sum / requests_raw",
        "feature_custom_num_1_sum": "feature_custom_num_1_sum",
        "feature_custom_num_2_avg": "feature_custom_num_2_sum / requests_raw",
        "feature_custom_num_2_sum": "feature_custom_num_2_sum",
        "feature_custom_num_3_avg": "feature_custom_num_3_sum / requests_raw",
        "feature_custom_num_3_sum": "feature_custom_num_3_sum",
        "floor_raw_model_sum": "floor_raw_model_sum / 1000",
        "floor_raw_model_sum_cpm": "(floor_raw_model_sum / 1000 / requests_raw) * 1000",
        "floor_sum": "floor_sum / 1000",
        "floor_sum_cpm": "(floor_sum / 1000 / requests_raw) * 1000",
        "lost_bids_rate": "bid_false_negative / bids_raw",
        "lost_revenue_rate": "missed_revenue / 1000 / (win_revenue / 1000)",
        "lost_wins_rate": "win_false_negative / wins_raw",
        "missed_revenue": "missed_revenue / 1000",
        "predicts": "predicts",
        "reduction_efficiency": "1 - ((bid_true_negative + bid_false_negative) / requests_raw) * (missed_revenue / 1000 / (win_revenue / 1000)) * 100",
        "req_reduction_rate": "(bid_true_negative + bid_false_negative) / requests_raw",
        "request_rpmm": "(win_revenue / 1000 / requests_raw) * 1000 * 1000",
        "requests": "requests_raw",
        "retained_request_rpmm": "(retained_revenue / 1000 / retained_requests) * 1000 * 1000",
        "retained_requests": "retained_requests",
        "retained_revenue": "retained_revenue / 1000",
        "rpb": "(win_revenue / 1000 / wins_raw) * 1000000000",
        "session_bid_rate": "session_bids_sum / session_requests_sum",
        "session_bids_sum": "session_bids_sum",
        "session_requests_sum": "session_requests_sum",
        "win_accuracy": "100 * ((win_true_positive + win_true_negative) / requests_raw)",
        "win_cpm": "(win_revenue / 1000 / wins_raw) * 1000",
        "win_precision": "100 * (win_true_positive / (win_true_positive + win_false_positive))",
        "win_rate": "wins_raw / requests_raw",
        "win_recall": "100 * (win_true_positive / (win_true_positive + win_false_negative))",
        "win_revenue": "win_revenue / 1000",
        "win_specificity": "100 * (win_true_negative / (win_true_negative + win_false_positive))",
        "wins": "wins_raw"
    },
    "traffic_shaping_server_samples_profile": {
        "bid_cpm": "(bid_revenue / 1000 / bids_raw) * 1000",
        "bid_rate": "bids_raw / requests_raw",
        "bid_revenue": "bid_revenue / 1000",
        "bids": "bids_raw",
        "feature_bid_cpm_cache_avg": "(feature_bid_cpm_cache_sum / 1000 / requests_raw) * 1000 * 1000",
        "feature_bid_cpm_cache_sum": "feature_bid_cpm_cache_sum / 1000",
        "feature_bid_sum_avg": "(feature_bid_sum_sum / 1000 / requests_raw) * 1000 * 1000",
        "feature_bid_sum_sum": "feature_bid_sum_sum / 1000",
        "feature_custom_num_1_avg": "feature_custom_num_1_sum / requests_raw",
        "feature_custom_num_1_sum": "feature_custom_num_1_sum",
        "feature_custom_num_2_avg": "feature_custom_num_2_sum / requests_raw",
        "feature_custom_num_2_sum": "feature_custom_num_2_sum",
        "feature_custom_num_3_avg": "feature_custom_num_3_sum / requests_raw",
        "feature_custom_num_3_sum": "feature_custom_num_3_sum",
        "floor_raw_model_sum": "floor_raw_model_sum / 1000",
        "floor_raw_model_sum_cpm": "(floor_raw_model_sum / 1000 / requests_raw) * 1000",
        "floor_sum": "floor_sum / 1000",
        "floor_sum_cpm": "(floor_sum / 1000 / requests_raw) * 1000",
        "request_rpmm": "(win_revenue / 1000 / requests_raw) * 1000 * 1000",
        "requests": "requests_raw",
        "session_bid_rate": "session_bids_sum / session_requests_sum",
        "session_bids_sum": "session_bids_sum",
        "session_requests_sum": "session_requests_sum",
        "win_cpm": "(win_revenue / 1000 / wins_raw) * 1000",
        "win_rate": "wins_raw / requests_raw",
        "win_revenue": "win_revenue / 1000",
        "wins": "wins_raw"
    },
    "traffic_shaping_server_floor_bandit": {
        "bid_cpm": "(revenue / bids) * 1000",
        "bid_rate": "bids / samples",
        "bids": "bids",
        "request_rpmm": "(revenue / samples) * 1000 * 1000",
        "revenue": "revenue",
        "samples": "samples",
        "win_cpm": "(revenue / wins) * 1000",
        "win_rate": "wins / samples",
        "wins": "wins"
    },
    "traffic_shaping_server_reduction_overview": {},
    "pbs_account_usage": {
        "incoming_auction_requests": "incoming_auction_requests"
    },
    "traffic_shaping_server_rpmm": {
        "is_qps_reduced": "is_qps_reduced",
        "is_reduction_reduced": "is_reduction_reduced",
        "predict_requests": "predict_requests",
        "retained_request_rpmm_with_samples": "(revenue / 1000 / (sent_optimize + sent_samples)) * 1000 * 1000",
        "retained_request_rpmm_without_samples": "(revenue / 1000 / sent_optimize) * 1000 * 1000",
        "revenue": "revenue / 1000",
        "sent_optimize": "sent_optimize",
        "sent_samples": "sent_samples",
        "sent_total": "sent_optimize + sent_samples"
    }
}