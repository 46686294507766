import { Gallery, Item } from "react-photoswipe-gallery";
import { Image as BuilderImage } from "@builder.io/react";
import { Builder } from "@builder.io/react";
import { BOOK_CALL_LINK } from "components/constants";
import CustomButton from "components/CustomButton";
import { toMutable } from "types/common";
import discovergrad from "./images/discover-yield-gradient.svg";
import glass from "./images/glass.svg";

const inputs = [
    {
        name: "heading",
        type: "text",
        defaultValue: "Discover Yield Manager",
        required: true,
    },
    {
        name: "subheading",
        type: "longText",
        defaultValue:
            "Without touching code, speed up release cycles 10x and simplify testing in real-time: ship fast, evaluate, revert, reduce risk, deploy. Supports multiple layouts and Single-page applications with ease of integration.",
        required: true,
    },
    {
        name: "title",
        type: "text",
        defaultValue: "Bid Pooling",
        required: true,
    },
    {
        name: "subtitle",
        type: "longText",
        defaultValue:
            "When there are several ad units with almost identical performance, creative size and demand on the page, no need to throw away all the good bids that came as second in an individual auction. With Bid Pooling, combine bids from several auctions and distribute the best ones to each ad unit.",
    },
    {
        name: "buttonText",
        type: "text",
        defaultValue: "Talk to sales",
        required: true,
    },
    {
        name: "buttonTo",
        type: "text",
        defaultValue: BOOK_CALL_LINK,
        required: true,
    },
    {
        name: "switchMagnifier",
        type: "boolean",
        defaultValue: true,
        enum: [
            {
                value: true,
                label: "Yes",
            },
            {
                value: false,
                label: "No",
            },
        ],
    },
    {
        name: "imageOrGif",
        type: "file",
        allowedFileTypes: ["jpg", "png", "jpeg", "gif", "svg"],
    },
    {
        name: "imageOrGifHover",
        type: "file",
        allowedFileTypes: ["jpg", "png", "jpeg", "gif", "svg"],
    },
    {
        name: "imageOrGifAlt",
        type: "text",
        defaultValue: "Gain Bidder Intelligence",
    },
    {
        name: "imageOrGifWidth",
        type: "number",
        defaultValue: 1338,
    },
    {
        name: "imageOrGifHeight",
        type: "number",
        defaultValue: 636,
    },
] as const;

const YieldManagerProductBlocks: BFC<typeof inputs> = ({
    heading,
    subheading,
    title,
    subtitle,
    buttonTo,
    switchMagnifier,
    buttonText,
    imageOrGif,
    imageOrGifHover,
    imageOrGifAlt,
    imageOrGifWidth,
    imageOrGifHeight,
}) => {
    return (
        <div className="component-wrapper">
            <img src={discovergrad} className={`gradient left`} style={{ top: "200px" }} alt="gradient" />
            <div className="container ">
                <div className="row justify-content-center text-lg-center mb-100 gx-5">
                    <div className="col-12 col-lg-8">
                        <h2>{heading}</h2>
                        <p>{subheading}</p>
                    </div>
                </div>
                <div className="row align-items-center gx-5 ">
                    <div className="col-lg-6">
                        <div className="mask-container  position-relative  mt-4 mb-4 mt-lg-0 mb-lg-0">
                            {imageOrGifHover ? (
                                <img
                                    onMouseOver={e => (e.currentTarget.src = imageOrGifHover)}
                                    onMouseOut={e => (e.currentTarget.src = imageOrGif!)}
                                    src={imageOrGif}
                                    alt={imageOrGifAlt}
                                    className="img-fluid"
                                />
                            ) : (
                                //@ts-ignore
                                <BuilderImage image={imageOrGif} lazy sizes="100%" altText={imageOrGifAlt} />
                            )}
                            {switchMagnifier && !imageOrGifHover && (
                                <Gallery>
                                    <Item
                                        width={imageOrGifWidth}
                                        height={imageOrGifHeight}
                                        original={imageOrGif}
                                        alt={imageOrGifAlt}
                                    >
                                        {({ ref, open }) => (
                                            // @ts-expect-error
                                            <div className="image_mask" ref={ref} onClick={open}>
                                                <img src={glass} alt="zoom image" />
                                            </div>
                                        )}
                                    </Item>
                                </Gallery>
                            )}
                        </div>
                    </div>
                    <div className="col-lg-6 mt-5 mt-lg-0">
                        <h2>{title}</h2>
                        <p className="mb-5">{subtitle}</p>
                        <CustomButton buttonStyle="btn--yellow-red" to={buttonTo}>
                            {buttonText}
                        </CustomButton>
                    </div>
                </div>
            </div>
        </div>
    );
};
Builder.registerComponent(YieldManagerProductBlocks, {
    name: "YieldManagerProductBlocks",
    inputs: toMutable(inputs),
});
