import type { MainMetricsType } from "../../../eda/types";
import type { ReportPlacementKeyType } from "../../placement.constants";
import { parsedPrettyJsMetrics } from "./pretty-data";

export const printParsedRecursiveMetricAsExprCached = (
    placement: ReportPlacementKeyType,
    id: MainMetricsType
): string => {
    return (parsedPrettyJsMetrics as any)?.[placement]?.[id] ?? "";
};
