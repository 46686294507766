import { CustomRangeConfig } from "../../codecs/custom-range-config.codec";
import { STD } from "../../utils/std";
import { EdaPlacementBuilder } from "../lib";
import { CalculationType } from "../types";
import yield_ from "./yield";

const yieldCalculationMeanTableMapping = Object.fromEntries(
    STD.getEntries(yield_.metricTable.tableMapping).map(([tabId, columnMapping]) => {
        const newColumnMapping = Object.fromEntries(
            Object.entries(columnMapping).map(([columnId, metrics]) => {
                return [
                    columnId,
                    metrics?.filter(metric => {
                        const calculationType = yield_.metrics[metric]?.calculationType;
                        return calculationType === CalculationType.Mean;
                    }) ?? [],
                ];
            })
        );

        return [tabId, newColumnMapping];
    })
);

export default new EdaPlacementBuilder()
    .withCustomRange(CustomRangeConfig.dateTime)
    .withTimeRanges([
        "Last 30 Minutes",
        "Last 1 Hour",
        "Last 6 Hours",
        "Last 12 Hours",
        "Today",
        "Yesterday",
        "Last 7 Days",
        "Last 30 Days",
        "This Month",
        "Last Month",
    ])
    .withTimeGranularities(["by_minute", "by_10_minutes", "by_hour", "by_day"])
    .withRawMetrics(yield_.rawMetrics)
    .withCalculatedMetricsIds(yield_.calculatedMetricsIds)
    .withCalculatedMetrics(yield_.metrics)
    .withInitialMetrics(yield_.initialMetrics)
    .withMetricTable({
        ...yield_.metricTable,
        tableMapping: yieldCalculationMeanTableMapping,
    })
    .withDimensions(yield_.dimensionsConfig);
