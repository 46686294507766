import { Gallery, Item } from "react-photoswipe-gallery";
import { Image as BuilderImage } from "@builder.io/react";
import { Builder } from "@builder.io/react";
import CustomButton from "components/CustomButton";
import TransparentGradientButton from "components/TransparentGradientButton";
import { toMutable } from "types/common";
import glass from "./images/glass.svg";
import * as styles from "./styles.module.scss";

const inputs = [
    {
        name: "title",
        type: "text",
        defaultValue: "Our mission is to make every team successful in their goals to increase revenue",
    },
    {
        name: "buttonText1",
        type: "text",
        defaultValue: "Learn about all our products",
        required: true,
    },
    {
        name: "buttonLink1",
        type: "text",
        defaultValue: "/",
        required: true,
    },
    {
        name: "buttonText2",
        type: "text",
        defaultValue: "View Pricing",
    },
    {
        name: "buttonLink2",
        type: "text",
        defaultValue: "/",
        required: true,
    },
    {
        name: "switchMagnifier",
        type: "boolean",
        defaultValue: true,
        enum: [
            {
                value: true,
                label: "Yes",
            },
            {
                value: false,
                label: "No",
            },
        ],
    },
    {
        name: "imageOrGif",
        type: "file",
        allowedFileTypes: ["jpg", "png", "jpeg", "gif", "svg"],
    },
    {
        name: "imageOrGifHover",
        type: "file",
        allowedFileTypes: ["jpg", "png", "jpeg", "gif", "svg"],
    },
    {
        name: "imageOrGifAlt",
        type: "text",
        defaultValue: "Gain Bidder Intelligence",
    },
    {
        name: "imageOrGifWidth",
        type: "number",
        defaultValue: 1161,
    },
    {
        name: "imageOrGifHeight",
        type: "number",
        defaultValue: 714,
    },
] as const;

const ByCompanyTypeOurMissionSuccessful: BFC<typeof inputs> = ({
    title,
    buttonText1,
    buttonLink1,
    buttonText2,
    buttonLink2,
    switchMagnifier,
    imageOrGif,
    imageOrGifHover,
    imageOrGifAlt,
    imageOrGifWidth,
    imageOrGifHeight,
}) => {
    return (
        <>
            <div className="component-wrapper">
                <div className="container ">
                    {/* <div className={`${styles.firstGlow} gradient`}>
            <StaticImage
              src="./images/by-company-type-data-is-power-first-glow.svg"
              alt=""
            />
          </div> */}
                    <div className="row align-items-center gx-5">
                        <div className="col-lg-6 order-2 order-lg-1 mt-5 mt-lg-0">
                            <h2 className={styles.title}>{title}</h2>
                            <div className={styles.buttonsWrapper}>
                                <div className={styles.gradientWrapper}>
                                    <TransparentGradientButton
                                        text={buttonText1}
                                        to={buttonLink1}
                                        bg="dark"
                                        bgColor={"#1f0746"}
                                    />
                                </div>
                                <CustomButton to={buttonLink2}>{buttonText2}</CustomButton>
                            </div>
                        </div>

                        <div className="col-lg-6 order-lg-1 mt-4 mb-4 mt-lg-0 mb-lg-0">
                            <div className="mask-container  position-relative ">
                                {imageOrGifHover ? (
                                    <img
                                        onMouseOver={e => (e.currentTarget.src = imageOrGifHover)}
                                        onMouseOut={e => (e.currentTarget.src = imageOrGif!)}
                                        src={imageOrGif}
                                        alt={imageOrGifAlt}
                                        className="img-fluid"
                                    />
                                ) : (
                                    //@ts-ignore
                                    <BuilderImage image={imageOrGif} lazy sizes="100%" altText={imageOrGifAlt} />
                                )}
                                {switchMagnifier && !imageOrGifHover && (
                                    <Gallery>
                                        <Item
                                            width={imageOrGifWidth}
                                            height={imageOrGifHeight}
                                            original={imageOrGif}
                                            alt={imageOrGifAlt}
                                        >
                                            {({ ref, open }) => (
                                                // @ts-expect-error
                                                <div className="image_mask" ref={ref} onClick={open}>
                                                    <img src={glass} alt="zoom image" />
                                                </div>
                                            )}
                                        </Item>
                                    </Gallery>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

Builder.registerComponent(ByCompanyTypeOurMissionSuccessful, {
    name: "byCompanyTypeOurMissionSuccessful",
    inputs: toMutable(inputs),
});
