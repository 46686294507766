import type { AdRevenueDimensionsIds } from "../eda/data/ad_revenue";
import type { BuydAdDimensionsIds } from "../eda/data/buy_ad";
import type { BuydAdsetDimensionsIds } from "../eda/data/buy_adset";
import type { BuyCampaignDimensionsIds } from "../eda/data/buy_campaign";
import type { DemandReportDimensionsIds } from "../eda/data/demand";
import type { DiscrepancyRevenueReportDimensionsIds } from "../eda/data/discrepancy_revenue";
import type { ErrorAnalyticsDimensionsIds } from "../eda/data/error_analytics";
import type { ErrorLogDimensionsIds } from "../eda/data/error_log";
import type { FinancialReportDimensionsIds } from "../eda/data/financial";
import type { IndustryDimensionsIds } from "../eda/data/industry";
import type { PBSAccountUsageReportDimensionsIds } from "../eda/data/pbs_account_usage";
import type { PrebidDimensionsIds } from "../eda/data/prebid";
import type { RequestReductionClientDimensionsIds } from "../eda/data/request_reduction_client_v2";
import type { RequestReductionServerLatencyDimensionsIds } from "../eda/data/request_reduction_server_latency";
import type { SiteReportDimensionsIds } from "../eda/data/site";
import type { SpentBySectionReportDimensionsIds } from "../eda/data/spent_by_section";
import type { TrafficShapingServerFlooringDimensionsIds } from "../eda/data/traffic_shaping_server_floor_bandit";
import type { TrafficShapingServerReductionOverviewDimensionsIds } from "../eda/data/traffic_shaping_server_reduction_overview";
import type { TrafficShapingServerRPMMDimensionsIds } from "../eda/data/traffic_shaping_server_rpmm";
import type { RequestReductionServerSamplesPredictDimensionsIds } from "../eda/data/traffic_shaping_server_samples_predict";
import type { RequestReductionServerSamplesProfileDimensionsIds } from "../eda/data/traffic_shaping_server_samples_profile";
import type { WebDimensionsIds } from "../eda/data/web";
import type { YieldDimensionIds } from "../eda/data/yield/yield.dimensions";

export type DimensionKeys =
    | AdRevenueDimensionsIds
    | BuydAdDimensionsIds
    | BuydAdsetDimensionsIds
    | BuyCampaignDimensionsIds
    | DiscrepancyRevenueReportDimensionsIds
    | ErrorAnalyticsDimensionsIds
    | ErrorLogDimensionsIds
    | FinancialReportDimensionsIds
    | DemandReportDimensionsIds
    | IndustryDimensionsIds
    | PrebidDimensionsIds
    | RequestReductionClientDimensionsIds
    | RequestReductionServerLatencyDimensionsIds
    | RequestReductionServerSamplesPredictDimensionsIds
    | RequestReductionServerSamplesProfileDimensionsIds
    | SiteReportDimensionsIds
    | SpentBySectionReportDimensionsIds
    | TrafficShapingServerFlooringDimensionsIds
    | TrafficShapingServerReductionOverviewDimensionsIds
    | TrafficShapingServerRPMMDimensionsIds
    | WebDimensionsIds
    | YieldDimensionIds
    | PBSAccountUsageReportDimensionsIds;

export const RenderRowsCountByDimension = {
    hourOfDay: 24,
    dayOfWeek: 7,
    dayOfMonth: 31,
    monthOfYear: 12,
} as const;
