import { useState } from "react";
import { Gallery, Item } from "react-photoswipe-gallery";
import { StaticImage } from "gatsby-plugin-image";
import { Image as BuilderImage } from "@builder.io/react";
import { Builder } from "@builder.io/react";
import { toMutable } from "types/common";
import glass from "./images/glass.svg";
import * as styles from "./styles.module.scss";

const inputs = [
    {
        name: "suptitle",
        type: "text",
        defaultValue: "The Power of Yield Intelligence",
    },
    {
        name: "title",
        type: "text",
        defaultValue: "Data is Power",
    },
    {
        name: "subtitle",
        type: "longText",
        defaultValue:
            "Data-driven teams use Yield Intelligence, as their Single Source of Truth, to keep track of all revenue streams, perform deep analysis in real-time with accurate revenue predictions. Yield Optimizer automates Dynamic Flooring with the power of machine learning, preventing Bid Shading. As well, the Bid Request Optimization, as a Green Media Solution that reduces digital waste while increasing domain reputation.",
    },
    {
        name: "hideText",
        type: "longText",
        defaultValue:
            "Yield Manager is the most powerful Tag Manager tool in the market, which gives your teams the power to test scenarios (Vendors or Product details A/B testing) and preview results of changes, no code: without development team. It also includes BidCashing and BidPooling. Traffic Acquisition improves your buying decisions and bidding with real-time revenue attribution on UTM and multiple dimensions, also.fire pixels based on real-time revenue events and highly accurate predictions (94-98%).",
    },
    {
        name: "switchMagnifier",
        type: "boolean",
        defaultValue: true,
        enum: [
            {
                value: true,
                label: "Yes",
            },
            {
                value: false,
                label: "No",
            },
        ],
    },
    {
        name: "imageOrGif",
        type: "file",
        allowedFileTypes: ["jpg", "png", "jpeg", "gif", "svg"],
    },
    {
        name: "imageOrGifHover",
        type: "file",
        allowedFileTypes: ["jpg", "png", "jpeg", "gif", "svg"],
    },
    {
        name: "imageOrGifAlt",
        type: "text",
        defaultValue: "Gain Bidder Intelligence",
    },
    {
        name: "imageOrGifWidth",
        type: "number",
        defaultValue: 1161,
    },
    {
        name: "imageOrGifHeight",
        type: "number",
        defaultValue: 714,
    },
] as const;

const ByCompanyTypeDataIsPower: BFC<typeof inputs> = ({
    suptitle,
    title,
    subtitle,
    hideText,
    switchMagnifier,
    imageOrGif,
    imageOrGifHover,
    imageOrGifAlt,
    imageOrGifWidth,
    imageOrGifHeight,
}) => {
    const [showMore, setShowMore] = useState(false);

    return (
        <>
            <div className="component-wrapper">
                <div className="container ">
                    <div className={`${styles.firstGlow} gradient`}>
                        <StaticImage src="./images/by-company-type-data-is-power-first-glow.svg" alt="" />
                    </div>
                    <div className="row align-items-start gx-5">
                        <div className="col-lg-6 order-2 order-lg-1 mt-5 mt-lg-0">
                            <p className={styles.suptitle}>{suptitle}</p>
                            <h2>{title}</h2>
                            <p>{subtitle}</p>
                            {showMore && (
                                <div className={styles.fadeInDown}>
                                    <p>{hideText}</p>
                                </div>
                            )}
                            {!showMore && (
                                <div onClick={() => setShowMore(true)} className={styles.showMoreWrapper}>
                                    <div className={styles.showMoreIcon}>
                                        <StaticImage src="./images/by-company-type-data-is-power-icon-add.svg" alt="" />
                                    </div>
                                    <p className={styles.showMoreText}>Show more</p>
                                </div>
                            )}

                            {showMore && (
                                <div onClick={() => setShowMore(false)} className={styles.showMoreWrapper}>
                                    <div className={styles.showMoreIcon}>
                                        <StaticImage
                                            src="./images/by-company-type-data-is-power-icon-remove.svg"
                                            alt=""
                                        />
                                    </div>
                                    <p className={styles.showMoreText}>Show less</p>
                                </div>
                            )}
                        </div>

                        <div className="col-lg-6 order-lg-1 mt-4 mb-4 mt-lg-0 mb-lg-0">
                            <div className="mask-container  position-relative ">
                                {imageOrGifHover ? (
                                    <img
                                        onMouseOver={e => (e.currentTarget.src = imageOrGifHover)}
                                        onMouseOut={e => (e.currentTarget.src = imageOrGif!)}
                                        src={imageOrGif}
                                        alt={imageOrGifAlt}
                                        className="img-fluid"
                                    />
                                ) : (
                                    //@ts-ignore
                                    <BuilderImage image={imageOrGif} lazy sizes="100%" altText={imageOrGifAlt} />
                                )}
                                {switchMagnifier && !imageOrGifHover && (
                                    <Gallery>
                                        <Item
                                            width={imageOrGifWidth}
                                            height={imageOrGifHeight}
                                            original={imageOrGif}
                                            alt={imageOrGifAlt}
                                        >
                                            {({ ref, open }) => (
                                                // @ts-expect-error
                                                <div className="image_mask" ref={ref} onClick={open}>
                                                    <img src={glass} alt="zoom image" />
                                                </div>
                                            )}
                                        </Item>
                                    </Gallery>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

Builder.registerComponent(ByCompanyTypeDataIsPower, {
    name: "byCompanyTypeDataIsPower",
    inputs: toMutable(inputs),
});
