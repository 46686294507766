import { useState } from "react";
import { Gallery, Item } from "react-photoswipe-gallery";
import { StaticImage } from "gatsby-plugin-image";
import { Builder } from "@builder.io/react";
import { Image as BuilderImage } from "@builder.io/react";
import { toMutable } from "types/common";
import glass from "./images/glass.svg";
import * as styles from "./styles.module.scss";

const inputs = [
    {
        name: "title",
        type: "text",
        defaultValue: "Data is Power for World Leading SSPs",
    },
    {
        name: "subtitle",
        type: "longText",
        defaultValue:
            "Publishers can expect better results by working with many Supply Side Platforms simultaneously and opening up new avenues of demand. AY gives the tools to SSPs to make these workflows extraordinary.",
    },
    {
        name: "boldText",
        type: "text",
        defaultValue: "What are the issues we are solving with SSPs?",
    },
    {
        name: "subtitle2",
        type: "longText",
        defaultValue:
            "Supply Side Platforms often face greater competition for demand in the industry, duplication of auctions with very high infrastructure cost.",
    },
    {
        name: "hideText",
        type: "longText",
        defaultValue:
            "AY Traffic Shaping tool astoundingly improves the quality of the inventory, resulting in higher overall bid rates and traded volume leading to more revenue and a significant reduction in the server cost. Our product allows you to optimize and manage your QPS budget with all of your DSP partners - in real-time, meeting the limits per second with the help of machine learning and user profiling. You can simply share the data predictions with your product or engineering teams",
    },
    {
        name: "switchMagnifier",
        type: "boolean",
        defaultValue: true,
        enum: [
            {
                value: true,
                label: "Yes",
            },
            {
                value: false,
                label: "No",
            },
        ],
    },
    {
        name: "imageOrGif",
        type: "file",
        allowedFileTypes: ["jpg", "png", "jpeg", "gif", "svg"],
    },
    {
        name: "imageOrGifHover",
        type: "file",
        allowedFileTypes: ["jpg", "png", "jpeg", "gif", "svg"],
    },
    {
        name: "imageOrGifAlt",
        type: "text",
        defaultValue: "Gain Bidder Intelligence",
    },
    {
        name: "imageOrGifWidth",
        type: "number",
        defaultValue: 1161,
    },
    {
        name: "imageOrGifHeight",
        type: "number",
        defaultValue: 714,
    },
] as const;

const ByCompanyTypeDataIsPowerSsp: BFC<typeof inputs> = ({
    title,
    subtitle,
    boldText,
    subtitle2,
    hideText,
    switchMagnifier,
    imageOrGif,
    imageOrGifHover,
    imageOrGifAlt,
    imageOrGifWidth,
    imageOrGifHeight,
}) => {
    const [showMore, setShowMore] = useState(false);

    return (
        <>
            <div className="component-wrapper">
                <div className="container ">
                    <div className={`${styles.firstGlow} gradient`}>
                        <StaticImage src="./images/by-company-type-data-is-power-first-glow.svg" alt="" />
                    </div>
                    <div className="row align-items-start gx-5">
                        <div className="col-lg-6 order-2 order-lg-1 mt-5 mt-lg-0">
                            <h2>{title}</h2>
                            <p>{subtitle}</p>
                            <p className="font-weight-bold">{boldText}</p>
                            <p>{subtitle2}</p>
                            {showMore && (
                                <div className={styles.fadeInDown}>
                                    <p>{hideText}</p>
                                </div>
                            )}
                            {!showMore && (
                                <div onClick={() => setShowMore(true)} className={styles.showMoreWrapper}>
                                    <div className={styles.showMoreIcon}>
                                        <StaticImage src="./images/by-company-type-data-is-power-icon-add.svg" alt="" />
                                    </div>
                                    <p className={styles.showMoreText}>Show more</p>
                                </div>
                            )}

                            {showMore && (
                                <div onClick={() => setShowMore(false)} className={styles.showMoreWrapper}>
                                    <div className={styles.showMoreIcon}>
                                        <StaticImage
                                            src="./images/by-company-type-data-is-power-icon-remove.svg"
                                            alt=""
                                        />
                                    </div>
                                    <p className={styles.showMoreText}>Show less</p>
                                </div>
                            )}
                        </div>

                        <div className="col-lg-6 order-lg-1 mt-4 mb-4 mt-lg-0 mb-lg-0">
                            <div className="mask-container  position-relative ">
                                {imageOrGifHover ? (
                                    <img
                                        onMouseOver={e => (e.currentTarget.src = imageOrGifHover)}
                                        onMouseOut={e => (e.currentTarget.src = imageOrGif!)}
                                        src={imageOrGif}
                                        alt={imageOrGifAlt}
                                        className="img-fluid"
                                    />
                                ) : (
                                    //@ts-ignore
                                    <BuilderImage image={imageOrGif} lazy sizes="100%" altText={imageOrGifAlt} />
                                )}
                                {switchMagnifier && !imageOrGifHover && (
                                    <Gallery>
                                        <Item
                                            width={imageOrGifWidth}
                                            height={imageOrGifHeight}
                                            original={imageOrGif}
                                            alt={imageOrGifAlt}
                                        >
                                            {({ ref, open }) => (
                                                // @ts-expect-error
                                                <div className="image_mask" ref={ref} onClick={open}>
                                                    <img src={glass} alt="zoom image" />
                                                </div>
                                            )}
                                        </Item>
                                    </Gallery>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

Builder.registerComponent(ByCompanyTypeDataIsPowerSsp, {
    name: "byCompanyTypeDataIsPowerSsp",
    inputs: toMutable(inputs),
});
