import type { BeffParser, TypeOf } from "@beff/cli";
import { printErrors } from "@beff/client";
import eq from "fast-deep-equal";
import stringify from "json-stable-stringify";
import { LRUCache } from "lru-cache";

export namespace STD {
    export const uniqueBy = <T>(arr: T[], keyFn: (it: T) => string): T[] => {
        const map = new Map<string, T>();
        for (const it of arr) {
            map.set(keyFn(it), it);
        }
        return Array.from(map.values());
    };

    export const uppercaseFirstLetter = (s: string): string => s.charAt(0).toUpperCase() + s.slice(1);

    export const uppercaseFirstLetterLowerRest = (s: string): string =>
        s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();

    export const getKeys = <T extends object>(obj: T) => Object.keys(obj) as Array<keyof T>;
    export const getValues = <T extends object>(obj: T) => Object.values(obj) as Array<T[keyof T]>;

    export const fromEntries = <K extends string | number | symbol, V>(entries: Array<[K, V]>) =>
        Object.fromEntries(entries) as Record<K, V>;

    export const getEntries = <K extends string | number | symbol, V>(obj: Record<K, V>) =>
        Object.entries(obj) as Array<[K, V]>;

    export const getEntriesPartial = <K extends string | number | symbol, V>(obj: Partial<Record<K, V>>) =>
        Object.entries(obj) as Array<[K, V]>;

    export const makeArrayNonNull = <T>(it: T | readonly T[]): T[] => {
        if (Array.isArray(it)) {
            return [...it];
        }

        return [it as any];
    };
    export const makeArray = <T>(it: T | readonly T[] | null | undefined): T[] => {
        if (it == null) {
            return [];
        }
        return makeArrayNonNull(it);
    };

    export const removeNullishFromList = <T>(list: readonly (T | null | undefined)[]): readonly T[] =>
        list.reduce((p, c) => (c != null ? [...p, c] : p), [] as T[]);

    export type Updater<A> = (v: A) => A;
    export const chainUpdater =
        <A>(mod2: Updater<A>) =>
        //
        (mod1: Updater<A>): Updater<A> =>
        //
        (value: A) =>
            mod2(mod1(value));

    export const memoizer = <I extends any[], O>(fn: (...a: I) => O) => {
        const _cache: { [k: string]: O } = {};

        return (...args: I) => {
            let key = "";
            try {
                if (args.length === 1) {
                    const singleArg = args[0];
                    if (typeof singleArg === "string") {
                        key = singleArg;
                    } else if (typeof singleArg === "number" || typeof singleArg === "boolean") {
                        key = String(singleArg);
                    } else {
                        key = JSON.stringify(singleArg);
                    }
                } else {
                    key = JSON.stringify(args);
                }
            } catch (e) {
                key = args.join("");
            }
            const cached = _cache[key];
            if (cached != null) {
                return cached;
            }

            const val = fn(...args);
            _cache[key] = val;
            return val;
        };
    };

    export const promiseOrTimeout = async <T>(name: string, promise: Promise<T>, timeoutMs: number): Promise<T> => {
        let timeoutId: any;
        const timeoutPromise = new Promise<T>((_, reject) => {
            timeoutId = setTimeout(() => {
                reject(new Error(`${name} - Timeout of ${timeoutMs / 1000 / 60} minutes reached`));
            }, timeoutMs);
        });

        try {
            const result = await Promise.race([promise, timeoutPromise]);
            clearTimeout(timeoutId);
            return result;
        } catch (e) {
            clearTimeout(timeoutId);
            throw e;
        }
    };

    export const reverseKv = (it: Record<string, string>): Record<string, string> => {
        const result: Record<string, string> = {};
        for (const [k, v] of Object.entries(it)) {
            result[v] = k;
        }
        return result;
    };

    export const deepEqual = <T>(a: T, b: T): boolean => {
        return eq(a, b);
    };

    export type DeepReadonly<T> = T extends (infer R)[]
        ? DeepReadonlyArray<R>
        : T extends Function
          ? T
          : T extends object
            ? DeepReadonlyObject<T>
            : T;

    interface DeepReadonlyArray<T> extends ReadonlyArray<DeepReadonly<T>> {}

    type DeepReadonlyObject<T> = {
        readonly [P in keyof T]: DeepReadonly<T[P]>;
    };

    export const memoizedLRU = <C extends BeffParser<any>, O>(
        codec: C,
        func: (args: TypeOf<C>) => O
    ): ((args: TypeOf<C>) => O) => {
        const memoStore = new LRUCache<string, any>({
            max: 300,
        });
        return (args: TypeOf<C>): O => {
            const parsed = codec.safeParse(args);
            if (!parsed.success) {
                throw new Error("Invalid arguments passed to memoized function. s" + printErrors(parsed.errors));
            }
            const key = stringify(parsed.data);
            if (!memoStore.has(key)) {
                const result = func(args);
                memoStore.set(key, result);
            }

            return memoStore.get(key);
        };
    };

    export const isPromise = <T>(value: unknown): value is Promise<T> =>
        typeof value === "object" && value !== null && typeof (value as Promise<T>).then === "function";

    export type OrPromise<T> = T | Promise<T>;

    export const maybePromiseApply = <T, R>(value: OrPromise<T>, func: (value: T) => R): OrPromise<R> => {
        if (isPromise(value)) {
            return value.then(func);
        }
        return func(value);
    };

    export const maybePromiseApply2 = <T1, T2, R>(
        value1: OrPromise<T1>,
        value2: OrPromise<T2>,
        func: (value1: T1, value2: T2) => R
    ): OrPromise<R> => {
        if (isPromise(value1) || isPromise(value2)) {
            return Promise.all([value1, value2]).then(([value1, value2]) => func(value1, value2));
        }
        return func(value1, value2);
    };
}
