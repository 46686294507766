import type { FC } from "react";
import { Image as BuilderImage } from "@builder.io/react";
import * as allStyles from "components/all.module.scss";
import CustomButton, { CustomButtonStyles } from "components/CustomButton";
import * as styles from "./products.module.scss";

const ProductsHero: FC<{
    description: string;
    heading: string;
    heroimage: string;
    gradient: {
        right: string;
        rightTop: string;
        rightBottom: string;
        left: string;
        leftTop: string;
        leftBottom: string;
    };
    showForm: boolean;
    buttonTitle: string;
    buttonTo: string;
    iconTitle: string;
    icon: string;
    buttonStyle?: CustomButtonStyles;
    showNoteWithLinkTrafficAcquisition?: boolean;
    heroimageHover?: string;
    heroimageAlt?: string;
    moreContent?: boolean;
    secondDescription?: string;
}> = ({
    description,
    heading,
    heroimage,
    heroimageHover,
    heroimageAlt,
    gradient,
    showForm,
    buttonTitle,
    buttonTo,
    iconTitle,
    icon,
    buttonStyle,
    showNoteWithLinkTrafficAcquisition,
    moreContent,
    secondDescription,
}) => {
    return (
        <>
            {gradient ? (
                <img
                    src={gradient?.left}
                    className={`gradient left`}
                    style={{ top: gradient?.leftTop, bottom: gradient?.leftBottom }}
                    alt=""
                />
            ) : (
                ""
            )}

            <img
                src={gradient?.right}
                className={`gradient right`}
                style={{ top: gradient?.rightTop, bottom: gradient?.rightBottom }}
            />
            <div className={`${allStyles.component_wrapper_hero} common-bg`}>
                <div className={`container panel`}>
                    <div className="row align-items-center gx-5">
                        <div className={`${moreContent ? "col-lg-7" : "col-lg-6"} order-2 order-lg-1`}>
                            <div className={`${styles.prodIco} d-flex align-items-center mb-3`}>
                                <img src={icon ? icon : ""} alt="" />
                                <h4 className="ms-3 mb-0">{iconTitle ? iconTitle : ""}</h4>
                            </div>
                            <h1 className="mb-4">{heading}</h1>

                            <p className="mb-3">{description}</p>
                            <p className="mb-5">{secondDescription}</p>
                            {showForm ? (
                                <div className="row">
                                    <div className="col-auto col-sm-8 d-none d-lg-block">
                                        <div className="input-group input-group-lg">
                                            <input
                                                type="email"
                                                className="form-control bg-white"
                                                placeholder="Work Email"
                                            />
                                        </div>
                                    </div>
                                    <div className="col col-lg-auto text-center mt-lg-0 ">
                                        <CustomButton buttonSize="btn--large" buttonStyle={buttonStyle} to={buttonTo}>
                                            {buttonTitle ? buttonTitle : "Start for free"}
                                        </CustomButton>
                                    </div>
                                </div>
                            ) : (
                                <div className="mb-4">
                                    <CustomButton buttonSize="btn--large" buttonStyle={buttonStyle} to={buttonTo}>
                                        {buttonTitle ? buttonTitle : "Start for free"}
                                    </CustomButton>
                                    <div>
                                        {showNoteWithLinkTrafficAcquisition && (
                                            <p className={`d-md-block d-none mt-3 ${styles.note}`}>
                                                Free for up to 10mm/m impressions. No credit card required. For details
                                                see{" "}
                                                <a href="/pricing/" className={styles.noteLink}>
                                                    pricing
                                                </a>
                                                .
                                            </p>
                                        )}
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className={`${moreContent ? "col-lg-5" : "col-lg-6"} order-1 order-lg-1 mb-5 mb-lg-0`}>
                            <div className="hero-image text-center">
                                {heroimageHover ? (
                                    <img
                                        onMouseOver={e => (e.currentTarget.src = heroimageHover)}
                                        onMouseOut={e => (e.currentTarget.src = heroimage!)}
                                        src={heroimage}
                                        alt={heroimageAlt}
                                        width="100%"
                                    />
                                ) : (
                                    //@ts-ignore
                                    <BuilderImage altText={heroimageAlt} image={heroimage} sizes="100%" />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default ProductsHero;
