import { StaticImage } from "gatsby-plugin-image";
import { Builder } from "@builder.io/react";
import { Image as BuilderImage } from "@builder.io/react";
import CustomButton from "components/CustomButton";
import { toMutable } from "types/common";
import * as styles from "./styles.module.scss";

const inputs = [
    {
        name: "title",
        type: "text",
        defaultValue: "Our Dream Team",
    },
    {
        name: "subtitle",
        type: "longText",
        defaultValue:
            "Our team comprises a talented pool of professionals and technical minds with expertise in the field. We are young, hardworking and we push ourselves to provide the best to our customers.",
    },
    {
        name: "teamTypes",
        type: "list",
        subFields: [
            {
                name: "name",
                type: "text",
                defaultValue: "Management Team",
                required: true,
            },
            {
                name: "team",
                type: "list",
                subFields: [
                    {
                        name: "image",
                        type: "file",
                        allowedFileTypes: ["jpg", "png", "jpeg"],
                        defaultValue: "https://via.placeholder.com/200",
                        required: true,
                    },
                    {
                        name: "name",
                        type: "text",
                        defaultValue: "Nils Lind",
                        required: true,
                    },
                    {
                        name: "position",
                        type: "text",
                        defaultValue: "CEO, Company",
                        required: true,
                    },
                ],
                defaultValue: [
                    {
                        image: "https://via.placeholder.com/200",
                        name: "Nils Lind",
                        position: "Founder",
                    },
                    {
                        image: "https://via.placeholder.com/200",
                        name: "Ryan Abrahams",
                        position: "Chief Revenue Officer",
                    },
                    {
                        image: "https://via.placeholder.com/200",
                        name: "Sherzod Rizaev",
                        position: "Chief Operating Officer",
                    },
                    {
                        image: "https://via.placeholder.com/200",
                        name: "Katia Moura",
                        position: "Chief Marketing Officer",
                    },
                    {
                        image: "https://via.placeholder.com/200",
                        name: "Frederic Lamoulie",
                        position: "SVP, Sales EMEA",
                    },
                ],
            },
        ],
    },
] as const;

const AboutUsOurDreamTeam: BFC<typeof inputs> = ({ title, subtitle, teamTypes }) => {
    return (
        <div className="content-dark">
            <div className={`${styles.firstGlow} gradient`}>
                <StaticImage src="./images/about-us-our-dream-team-first-glow.svg" alt="" />
            </div>
            <div className={`${styles.secondGlow} gradient`}>
                <StaticImage src="./images/about-us-our-dream-team-second-glow.svg" alt="" />
            </div>
            {/* <div className={`${styles.thirdGlow} gradient`}>
        <StaticImage
          src="./images/about-us-our-dream-team-second-glow.svg"
          alt=""
        />
      </div>
      <div className={`${styles.fourthGlow} gradient`}>
        <StaticImage
          src="./images/about-us-our-dream-team-fourth-glow.svg"
          alt=""
        />
      </div> */}
            <div className="container">
                <div className={styles.wrapper}>
                    <h2 className={styles.title}>{title}</h2>
                    <p className={styles.subtitle}>{subtitle}</p>

                    {teamTypes?.map((teamType, index) => (
                        <>
                            <h4 key={index} className={styles.teamTitle}>
                                {teamType.name}
                            </h4>
                            <div className={styles.list}>
                                {teamType.team?.map((item, index) => (
                                    <div className={styles.item} key={index}>
                                        <div className={styles.image}>
                                            {/* @ts-ignore */}
                                            <BuilderImage image={item.image} lazy />
                                        </div>
                                        <div className={styles.descriptionWrapper}>
                                            <h5 className={styles.name}>{item.name}</h5>
                                            <p className={styles.position}>{item.position}</p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </>
                    ))}
                    <CustomButton to="/help-center">Explore our Customer Support & Services</CustomButton>
                </div>
            </div>
        </div>
    );
};

Builder.registerComponent(AboutUsOurDreamTeam, {
    name: "AboutUsOurDreamTeam",
    inputs: toMutable(inputs),
});
