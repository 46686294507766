import { Link } from "gatsby";
import { Builder } from "@builder.io/react";
import { Image as BuilderImage } from "@builder.io/react";
import { toMutable } from "types/common";
import * as styles from "./styles.module.scss";

const inputs = [
    {
        name: "title",
        type: "text",
        defaultValue: "Want to explore further?",
    },
    {
        name: "description",
        type: "text",
        defaultValue: "Discover helpful resources for deep-dive industry knowledge",
    },
    {
        name: "cards",
        type: "list",
        subFields: [
            {
                name: "title",
                type: "text",
                defaultValue: "AY Support",
            },
            {
                name: "link",
                type: "text",
                defaultValue: "/",
            },
            {
                name: "icon",
                type: "file",
                allowedFileTypes: ["svg"],
            },
            {
                name: "description",
                type: "text",
                defaultValue: "Experience faster and more effective assistance with our exclusive AY support",
            },
        ],
        defaultValue: [
            {
                title: "AY Support",
                icon: "",
                description: "Experience faster and more effective assistance with our exclusive AY support",
            },
        ],
    },
] as const;

export const HelpfulResources: BFC<typeof inputs> = ({ title, description, cards }) => {
    return (
        <div className="container">
            <h2 className={styles.title}>{title}</h2>
            <p className={styles.description}>{description}</p>
            <div className={styles.cards}>
                {cards?.map((card, index) => (
                    <Link to={card.link!} className={styles.card} key={index}>
                        <h3 className={styles.title}>{card.title}</h3>
                        <div>
                            {/* @ts-ignore */}
                            <BuilderImage image={card.icon} lazy />
                        </div>
                        <p className={styles.description}>{card.description}</p>
                    </Link>
                ))}
            </div>
        </div>
    );
};

export default HelpfulResources;

Builder.registerComponent(HelpfulResources, {
    name: "helpfulResources",
    inputs: toMutable(inputs),
});
