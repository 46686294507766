import { Gallery, Item } from "react-photoswipe-gallery";
import { Builder } from "@builder.io/react";
import { Image as BuilderImage } from "@builder.io/react";
import { SIGNUP_LINK } from "components/constants";
import CustomButton from "components/CustomButton";
import { toMutable } from "types/common";
import glass from "./images/glass.svg";
import * as styles from "./styles.module.scss";

const inputs = [
    {
        name: "suptitle",
        type: "text",
        defaultValue: "track partners, formats, placements & more",
    },
    {
        name: "title",
        type: "text",
        defaultValue: "Cross Filters Alerts",
        required: true,
    },
    {
        name: "subtitle",
        type: "longText",
        defaultValue:
            "Highly customized alert that spans hundreds of dimensions and metrics in real-time allows you to immediately discover bugs to prevent inventory data loss and develop new insights based on data acquired on-demand or recombined according to your specifications.",
        required: true,
    },
    {
        name: "subtitle2",
        type: "longText",
        defaultValue:
            "Trigger alerts on any combination of dimensions, e.g. Device, Country, Browser + Exchange or Campaign",
    },
    {
        name: "buttonText",
        type: "text",
        defaultValue: "Try it Out, It's Free",
        required: true,
    },
    {
        name: "linkTo",
        type: "text",
        defaultValue: SIGNUP_LINK,
        required: true,
    },
    {
        name: "switchMagnifier",
        type: "boolean",
        defaultValue: true,
        enum: [
            {
                value: true,
                label: "Yes",
            },
            {
                value: false,
                label: "No",
            },
        ],
    },
    {
        name: "imageOrGif",
        type: "file",
        allowedFileTypes: ["jpg", "png", "jpeg", "gif", "svg"],
    },
    {
        name: "imageOrGifHover",
        type: "file",
        allowedFileTypes: ["jpg", "png", "jpeg", "gif", "svg"],
    },
    {
        name: "imageOrGifAlt",
        type: "text",
        defaultValue: "Gain Bidder Intelligence",
    },
    {
        name: "imageOrGifWidth",
        type: "number",
        defaultValue: 760,
    },
    {
        name: "imageOrGifHeight",
        type: "number",
        defaultValue: 735,
    },
] as const;
const MonitoringAlerts: BFC<typeof inputs> = ({
    suptitle,
    title,
    subtitle,
    subtitle2,
    linkTo,
    switchMagnifier,
    buttonText,
    imageOrGif,
    imageOrGifHover,
    imageOrGifAlt,
    imageOrGifWidth,
    imageOrGifHeight,
}) => {
    return (
        <>
            <div className="component-wrapper">
                <div className="container ">
                    <div className="row align-items-center gx-5">
                        <div className="col-lg-6 order-lg-1 mt-4 mb-4 mt-lg-0 mb-lg-0">
                            <div className="mask-container  position-relative ">
                                {imageOrGifHover ? (
                                    <img
                                        onMouseOver={e => (e.currentTarget.src = imageOrGifHover)}
                                        onMouseOut={e => (e.currentTarget.src = imageOrGif!)}
                                        src={imageOrGif}
                                        alt={imageOrGifAlt}
                                        className="img-fluid"
                                    />
                                ) : (
                                    //@ts-ignore
                                    <BuilderImage image={imageOrGif} lazy sizes="100%" altText={imageOrGifAlt} />
                                )}
                                {switchMagnifier && !imageOrGifHover && (
                                    <Gallery>
                                        <Item
                                            width={imageOrGifWidth}
                                            height={imageOrGifHeight}
                                            original={imageOrGif}
                                            alt={imageOrGifAlt}
                                        >
                                            {({ ref, open }) => (
                                                // @ts-expect-error
                                                <div className="image_mask" ref={ref} onClick={open}>
                                                    <img src={glass} alt="zoom image" />
                                                </div>
                                            )}
                                        </Item>
                                    </Gallery>
                                )}
                            </div>
                        </div>
                        <div className="col-lg-6 order-2 order-lg-1 mt-5 mt-lg-0">
                            <p className={styles.suptitle}>{suptitle}</p>
                            <h2>{title}</h2>
                            <p>{subtitle}</p>
                            <p className="mb-5">{subtitle2}</p>
                            <CustomButton buttonStyle="btn--primary" to={linkTo}>
                                {buttonText}
                            </CustomButton>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

Builder.registerComponent(MonitoringAlerts, {
    name: "monitoringAlerts",
    inputs: toMutable(inputs),
});
