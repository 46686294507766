import { CustomRangeConfig } from "../../codecs/custom-range-config.codec";
import { EdaPlacementBuilder } from "../lib";
import type { PublicDimensionConfig } from "../types";

const DIMENSIONS = {} as const satisfies Record<string, Omit<PublicDimensionConfig, "id">>;

export type ErrorLogDimensionsIds = keyof typeof DIMENSIONS;

export default new EdaPlacementBuilder()
    .withCustomRange(CustomRangeConfig.off)
    .withTimeRanges(["Last 1 Hour", "Last 12 Hours", "Today", "Yesterday", "Last 7 Days", "Last 30 Days"])
    .withTimeGranularities([])
    .withRawMetrics({})
    .withCalculatedMetricsIds([])
    .withCalculatedMetrics({})
    .withInitialMetrics([])
    .withMetricTable({
        columnNames: {},
        tabMeta: {},
        tableMapping: {},
    })
    .withDimensions(DIMENSIONS);
