import { CustomRangeConfig } from "../../codecs/custom-range-config.codec";
import { EdaPlacementBuilder } from "../lib";
import type { PublicRawMetricConfig } from "../raw-metrics.service";
import { CalculationType, OptimizationObjective, type PublicDimensionConfig } from "../types";

const RAW_METRICS = {
    incoming_auction_requests: {},
} as const satisfies Record<string, PublicRawMetricConfig>;
export type PBSAccountUsageReportRawMetrics = keyof typeof RAW_METRICS;

const CALC_IDS = ["incoming_auction_requests"] as const;
export type PBSAccountUsageReportMetrics = (typeof CALC_IDS)[number];

const DIMENSIONS = {
    organizationId: {
        label: "Organization Id",
    },
} as const satisfies Record<string, Omit<PublicDimensionConfig, "id">>;

export type PBSAccountUsageReportDimensionsIds = keyof typeof DIMENSIONS;

export default new EdaPlacementBuilder()
    .withCustomRange(CustomRangeConfig.date)
    .withTimeRanges([
        "Last 30 Minutes",
        "Last 1 Hour",
        "Last 6 Hours",
        "Last 12 Hours",
        "Last 24 Hours",
        "Last 7 Days",
        "Last 30 Days",
    ])
    .withTimeGranularities(["by_minute", "by_10_minutes", "by_hour", "by_day", "by_week", "by_month", "by_quarter"])
    .withRawMetrics(RAW_METRICS)
    .withCalculatedMetricsIds(CALC_IDS)
    .withCalculatedMetrics({
        incoming_auction_requests: {
            label: "Incoming Auction Requests",
            explanation: "Incoming Auctions Requests",
            formula: m => m.incoming_auction_requests,
            calculationType: CalculationType.Sum,
            objective: OptimizationObjective.NoObjective,
        },
    })
    .withInitialMetrics(["incoming_auction_requests"])
    .withMetricTable({
        tabMeta: {
            overall: { name: "Overall", explanation: "PBS Account Usage Report Metrics" },
        },
        columnNames: {
            organizationId: "Organization Id",
            incoming_auction_requests: "Incoming Auctions Requests",
        },
        tableMapping: {
            overall: {
                incoming_auction_requests: ["incoming_auction_requests"],
            },
        },
    })
    .withDimensions(DIMENSIONS);
