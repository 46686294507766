import { useEffect, useRef } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { Autoplay, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { Builder } from "@builder.io/react";
import CustomButton from "components/CustomButton";
import SuccessStoriesCard from "components/SuccessStoriesCard/SuccessStoriesCard.builder";
import next from "images/next.png";
import prev from "images/prev.png";
import { SuccessStoriesData, toMutable } from "types/common";
import * as styles from "./styles.module.scss";

const inputs = [
    {
        name: "title",
        type: "string",
        defaultValue: "Don’t just take our word for it",
    },
    {
        name: "subtitle",
        type: "string",
        defaultValue: "See How Assertive Yield Has Empowered Publishers For Ultimate Revenue Growth",
    },
    {
        name: "buttonText",
        type: "string",
        defaultValue: "Discover More Customer Stories",
    },
    {
        name: "buttonLink",
        type: "string",
        defaultValue: "/success-stories",
    },
    {
        name: "isLight",
        type: "boolean",
        defaultValue: false,
    },
] as const;

export const SuccessStoriesCarousel: BFC<typeof inputs> = ({ title, subtitle, buttonText, buttonLink, isLight }) => {
    const { allSuccessStories }: { allSuccessStories: { nodes: SuccessStoriesData[] } } = useStaticQuery(graphql`
        query successStories {
            allSuccessStories {
                nodes {
                    id
                    clientLogoHover
                    clientLogoFileExternal {
                        childImageSharp {
                            gatsbyImageData(layout: FULL_WIDTH, quality: 90)
                        }
                    }
                    video
                    pdf
                    relatedFeatures
                    sidebarImageFileExternal {
                        childImageSharp {
                            gatsbyImageData(quality: 90)
                        }
                    }
                    title
                    description
                    category
                    fields {
                        slug
                    }
                    date
                }
            }
        }
    `);

    const stories = allSuccessStories.nodes.sort((a, b) => {
        const dateA = a.date ? new Date(a.date).getTime() : null;
        const dateB = b.date ? new Date(b.date).getTime() : null;

        if (dateA && dateB) {
            return dateB - dateA;
        }
        if (!dateA && !dateB) {
            return 0;
        }
        return dateA ? -1 : 1;
    });

    const buttonPrevRef = useRef<any>(null);
    const buttonNextRef = useRef<any>(null);
    const swiperInstance = useRef<any>(null);

    useEffect(() => {
        swiperInstance.current.params.navigation.prevEl = buttonPrevRef.current;
        swiperInstance.current.params.navigation.nextEl = buttonNextRef.current;
        swiperInstance.current.navigation.destroy();
        swiperInstance.current.navigation.init();
        swiperInstance.current.navigation.update();
    }, []);

    return (
        <div className={isLight ? styles.light : ""}>
            <div className="container">
                <div className={styles.wrapper}>
                    <div className={styles.headerWrapper}>
                        <div className={styles.headerContent}>
                            <h2>{title}</h2>
                            <p>{subtitle}</p>
                        </div>
                        <div className={`${styles.buttonWrapper} d-none d-lg-block`}>
                            <CustomButton customWidth="269px" buttonSize="btn--large" to={buttonLink}>
                                {buttonText}
                            </CustomButton>
                        </div>
                    </div>

                    <div className={`div swiper_container ms-0 mb-5 pb-3`}>
                        <Swiper
                            modules={[Autoplay, Navigation]}
                            slidesPerView="auto"
                            spaceBetween={20}
                            breakpoints={{
                                320: {
                                    slidesPerView: 1,
                                },
                                992: {
                                    slidesPerView: 2,
                                },
                            }}
                            loop={true}
                            navigation={{
                                prevEl: buttonPrevRef.current,
                                nextEl: buttonNextRef.current,
                            }}
                            autoplay={{
                                delay: 2500,
                                disableOnInteraction: false,
                            }}
                            onBeforeInit={swiper => {
                                swiperInstance.current = swiper;
                            }}
                        >
                            {stories.map(story => (
                                <SwiperSlide key={story.id}>
                                    <SuccessStoriesCard story={story} />
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </div>

                    <div className="d-block d-lg-none text-center">
                        <CustomButton
                            customWidth="300px"
                            buttonSize="btn--large"
                            buttonStyle="btn--primary"
                            to={buttonLink}
                        >
                            {buttonText}
                        </CustomButton>
                    </div>

                    <div className="d-lg-flex justify-content-end d-none">
                        <div ref={buttonPrevRef} className="btn--circle me-3">
                            <img src={prev} alt="" />
                        </div>

                        <div ref={buttonNextRef} className="btn--circle">
                            <img src={next} alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

Builder.registerComponent(SuccessStoriesCarousel, {
    name: "successStoriesCarousel",
    inputs: toMutable(inputs),
});
