import { Builder } from "@builder.io/react";
import { Image as BuilderImage } from "@builder.io/react";
import { toMutable } from "types/common";
import * as styles from "./styles.module.scss";

const inputs = [
    {
        name: "title",
        type: "text",
        defaultValue: "How can AY enhance your worth?",
    },
    {
        name: "list",
        type: "list",
        subFields: [
            {
                name: "title",
                type: "text",
                defaultValue: "Ad Operations & Yield",
            },
            {
                name: "image",
                type: "file",
                allowedFileTypes: ["jpg", "png", "jpeg", "gif", "svg"],
            },
        ],
    },
] as const;

const ByTeamTypeEnhanceWorth: BFC<typeof inputs> = ({ title, list }) => {
    return (
        <div className="component-wrapper">
            <div className="container ">
                <h2 className={styles.title}>{title}</h2>
                <div className={styles.list}>
                    {list?.map((item, index) => (
                        <div key={index}>
                            <div className={styles.icon}>
                                {/* @ts-ignore */}
                                <BuilderImage image={item.image} alt={item.title} />
                            </div>
                            <div className={styles.itemTitle}>{item.title}</div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

Builder.registerComponent(ByTeamTypeEnhanceWorth, {
    name: "byTeamTypeEnhanceWorth",
    inputs: toMutable(inputs),
});
