import { useState } from "react";
import { Gallery, Item } from "react-photoswipe-gallery";
import { StaticImage } from "gatsby-plugin-image";
import { Image as BuilderImage } from "@builder.io/react";
import { Builder } from "@builder.io/react";
import { toMutable } from "types/common";
import glass from "./images/glass.svg";
import * as styles from "./styles.module.scss";

const inputs = [
    {
        name: "mainTitle",
        type: "text",
        defaultValue: "Enabling teams to make independent decisions with more efficiency",
    },
    {
        name: "title",
        type: "text",
        defaultValue: "Make smarter choices with reliable data from AY",
    },
    {
        name: "subtitle",
        type: "longText",
        defaultValue:
            "AY is helping audience development teams to overcome the challenges of relying on 3rd party data by operating trustworthy data collection and also finding troubleshooting solutions, all in real time!",
    },
    {
        name: "hideText",
        type: "longText",
        defaultValue:
            "Learn how much each customer is worth in ad revenue. Which source, referral, even author brings in more Session RPM.",
    },
    {
        name: "switchMagnifier",
        type: "boolean",
        defaultValue: true,
        enum: [
            {
                value: true,
                label: "Yes",
            },
            {
                value: false,
                label: "No",
            },
        ],
    },
    {
        name: "imageOrGif",
        type: "file",
        allowedFileTypes: ["jpg", "png", "jpeg", "gif", "svg"],
    },
    {
        name: "imageOrGifHover",
        type: "file",
        allowedFileTypes: ["jpg", "png", "jpeg", "gif", "svg"],
    },
    {
        name: "imageOrGifAlt",
        type: "text",
        defaultValue: "Gain Bidder Intelligence",
    },
    {
        name: "imageOrGifWidth",
        type: "number",
        defaultValue: 1161,
    },
    {
        name: "imageOrGifHeight",
        type: "number",
        defaultValue: 714,
    },
] as const;

const ByTeamTypeSmarterChoice: BFC<typeof inputs> = ({
    mainTitle,
    title,
    subtitle,
    hideText,
    switchMagnifier,
    imageOrGif,
    imageOrGifHover,
    imageOrGifAlt,
    imageOrGifWidth,
    imageOrGifHeight,
}) => {
    const [showMore, setShowMore] = useState(false);

    return (
        <>
            <div className="component-wrapper">
                <div className="container ">
                    <div className={`${styles.firstGlow} gradient`}>
                        <StaticImage src="./images/by-company-type-data-is-power-first-glow.svg" alt="" />
                    </div>
                    <h2 className={styles.mainTitle}>{mainTitle}</h2>
                    <div className="row align-items-start gx-5 justify-content-between">
                        <div className={`col-lg-6 order-2 order-lg-1 mt-5 mt-lg-0 ${styles.content}`}>
                            <h2>{title}</h2>
                            <p>{subtitle}</p>
                            {showMore && (
                                <div className={styles.fadeInDown}>
                                    <p>{hideText}</p>
                                </div>
                            )}
                            {!showMore && (
                                <div onClick={() => setShowMore(true)} className={styles.showMoreWrapper}>
                                    <div className={styles.showMoreIcon}>
                                        <StaticImage src="./images/by-company-type-data-is-power-icon-add.svg" alt="" />
                                    </div>
                                    <p className={styles.showMoreText}>Show more</p>
                                </div>
                            )}

                            {showMore && (
                                <div onClick={() => setShowMore(false)} className={styles.showMoreWrapper}>
                                    <div className={styles.showMoreIcon}>
                                        <StaticImage
                                            src="./images/by-company-type-data-is-power-icon-remove.svg"
                                            alt=""
                                        />
                                    </div>
                                    <p className={styles.showMoreText}>Show less</p>
                                </div>
                            )}
                        </div>

                        <div className="col-lg-6 order-lg-1 mt-4 mb-4 mt-lg-0 mb-lg-0">
                            <div className="mask-container  position-relative ">
                                {imageOrGifHover ? (
                                    <img
                                        onMouseOver={e => (e.currentTarget.src = imageOrGifHover)}
                                        onMouseOut={e => (e.currentTarget.src = imageOrGif!)}
                                        src={imageOrGif}
                                        alt={imageOrGifAlt}
                                        className="img-fluid"
                                    />
                                ) : (
                                    //@ts-ignore
                                    <BuilderImage image={imageOrGif} lazy sizes="100%" altText={imageOrGifAlt} />
                                )}
                                {switchMagnifier && !imageOrGifHover && (
                                    <Gallery>
                                        <Item
                                            width={imageOrGifWidth}
                                            height={imageOrGifHeight}
                                            original={imageOrGif}
                                            alt={imageOrGifAlt}
                                        >
                                            {({ ref, open }) => (
                                                // @ts-expect-error
                                                <div className="image_mask" ref={ref} onClick={open}>
                                                    <img src={glass} alt="zoom image" />
                                                </div>
                                            )}
                                        </Item>
                                    </Gallery>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

Builder.registerComponent(ByTeamTypeSmarterChoice, {
    name: "byTeamTypeSmarterChoice",
    inputs: toMutable(inputs),
});
