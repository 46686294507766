import { Gallery, Item } from "react-photoswipe-gallery";
import { Builder } from "@builder.io/react";
import { Image as BuilderImage } from "@builder.io/react";
import { SIGNUP_LINK } from "components/constants";
import CustomButton from "components/CustomButton";
import { toMutable } from "types/common";
import glass from "./images/glass.svg";

const inputs = [
    {
        name: "title",
        type: "text",
        defaultValue: "Continuous Optimization",
        required: true,
    },
    {
        name: "subtitle1",
        type: "longText",
        defaultValue:
            "Find out how to get the most out of new strategies, analyze outcomes per country, per source, per layout, per content, even per user. Get the best performance of Ad refresh and Lazy Loading results by visualizing their performance in detail.",
        required: true,
    },
    {
        name: "subtitle2",
        type: "longText",
        defaultValue:
            "Try out custom bid pool and persistent bid caching aiming to keep the highest revenue you can get from an auction.",
    },
    {
        name: "buttonText",
        type: "text",
        defaultValue: "Start free trial",
        required: true,
    },
    {
        name: "buttonTo",
        type: "text",
        defaultValue: SIGNUP_LINK,
        required: true,
    },
    {
        name: "switchMagnifier",
        type: "boolean",
        defaultValue: true,
        enum: [
            {
                value: true,
                label: "Yes",
            },
            {
                value: false,
                label: "No",
            },
        ],
    },
    {
        name: "imageOrGif",
        type: "file",
        allowedFileTypes: ["jpg", "png", "jpeg", "gif", "svg"],
    },
    {
        name: "imageOrGifHover",
        type: "file",
        allowedFileTypes: ["jpg", "png", "jpeg", "gif", "svg"],
    },
    {
        name: "imageOrGifAlt",
        type: "text",
        defaultValue: "Gain Bidder Intelligence",
    },
    {
        name: "imageOrGifWidth",
        type: "number",
        defaultValue: 1161,
    },
    {
        name: "imageOrGifHeight",
        type: "number",
        defaultValue: 519,
    },
] as const;

const YieldManagerContinuousOptimization: BFC<typeof inputs> = ({
    title,
    subtitle1,
    subtitle2,
    buttonTo,
    switchMagnifier,
    buttonText,
    imageOrGif,
    imageOrGifHover,
    imageOrGifAlt,
    imageOrGifWidth,
    imageOrGifHeight,
}) => {
    return (
        <div className="component-wrapper">
            <div className="container ">
                <div className="row align-items-center gx-5 mt-5">
                    <div className="col-lg-6  order-2 order-lg-1">
                        <h2>{title}</h2>
                        <p className="mb-5">{subtitle1}</p>
                        <p>{subtitle2}</p>
                        <CustomButton buttonStyle="btn--yellow-red" to={buttonTo}>
                            {buttonText}
                        </CustomButton>
                    </div>
                    <div className="col-lg-6 order-1 mb-5 mb-lg-0 mt-5 mt-lg-0 ">
                        <div className="mask-container  position-relative  mt-4 mb-4 mt-lg-0 mb-lg-0">
                            {imageOrGifHover ? (
                                <img
                                    onMouseOver={e => (e.currentTarget.src = imageOrGifHover)}
                                    onMouseOut={e => (e.currentTarget.src = imageOrGif!)}
                                    src={imageOrGif}
                                    alt={imageOrGifAlt}
                                    className="img-fluid"
                                />
                            ) : (
                                //@ts-ignore
                                <BuilderImage image={imageOrGif} lazy sizes="100%" altText={imageOrGifAlt} />
                            )}
                            {switchMagnifier && !imageOrGifHover && (
                                <Gallery>
                                    <Item
                                        width={imageOrGifWidth}
                                        height={imageOrGifHeight}
                                        original={imageOrGif}
                                        alt={imageOrGifAlt}
                                    >
                                        {({ ref, open }) => (
                                            // @ts-expect-error
                                            <div className="image_mask" ref={ref} onClick={open}>
                                                <img src={glass} alt="zoom image" />
                                            </div>
                                        )}
                                    </Item>
                                </Gallery>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
Builder.registerComponent(YieldManagerContinuousOptimization, {
    name: "YieldManagerContinuousOptimization",
    inputs: toMutable(inputs),
});
