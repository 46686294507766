import { Gallery, Item } from "react-photoswipe-gallery";
import { Builder } from "@builder.io/react";
import { Image as BuilderImage } from "@builder.io/react";
import { BOOK_CALL_LINK } from "components/constants";
import CustomButton from "components/CustomButton";
import { toMutable } from "types/common";
import glass from "./images/glass.svg";

const inputs = [
    {
        name: "title",
        type: "text",
        defaultValue: "Visit & User Lifetime Value Optimization",
        required: true,
    },
    {
        name: "subtitle",
        type: "longText",
        defaultValue:
            "Get 2x times higher audience engagement by giving your team a simple and fast way to deploy and manage all existing tags on the analytics, ads, marketing, content, vendors/partners and tools. Choose the way they load, separate them by layouts, or any other custom triggers and compliment them with build in and custom variables.",
    },
    {
        name: "buttonText",
        type: "text",
        defaultValue: "Book a call",
        required: true,
    },
    {
        name: "buttonTo",
        type: "text",
        defaultValue: BOOK_CALL_LINK,
        required: true,
    },
    {
        name: "switchMagnifier",
        type: "boolean",
        defaultValue: true,
        enum: [
            {
                value: true,
                label: "Yes",
            },
            {
                value: false,
                label: "No",
            },
        ],
    },
    {
        name: "imageOrGif",
        type: "file",
        allowedFileTypes: ["jpg", "png", "jpeg", "gif", "svg"],
    },
    {
        name: "imageOrGifHover",
        type: "file",
        allowedFileTypes: ["jpg", "png", "jpeg", "gif", "svg"],
    },
    {
        name: "imageOrGifAlt",
        type: "text",
        defaultValue: "Gain Bidder Intelligence",
    },
    {
        name: "imageOrGifWidth",
        type: "number",
        defaultValue: 1338,
    },
    {
        name: "imageOrGifHeight",
        type: "number",
        defaultValue: 636,
    },
] as const;

const YieldManagerLifetimeValue: BFC<typeof inputs> = ({
    title,
    subtitle,
    buttonTo,
    switchMagnifier,
    buttonText,
    imageOrGif,
    imageOrGifHover,
    imageOrGifAlt,
    imageOrGifWidth,
    imageOrGifHeight,
}) => {
    return (
        <div className="component-wrapper">
            <div className="container ">
                <div className="row align-items-center gx-5 mt-5">
                    <div className="col-lg-6 mb-5 mb-lg-0 mt-5 mt-lg-0 ">
                        <div className="mask-container  position-relative">
                            {imageOrGifHover ? (
                                <img
                                    onMouseOver={e => (e.currentTarget.src = imageOrGifHover)}
                                    onMouseOut={e => (e.currentTarget.src = imageOrGif!)}
                                    src={imageOrGif}
                                    alt={imageOrGifAlt}
                                    className="img-fluid"
                                />
                            ) : (
                                //@ts-ignore
                                <BuilderImage image={imageOrGif} lazy sizes="100%" altText={imageOrGifAlt} />
                            )}
                            {switchMagnifier && !imageOrGifHover && (
                                <Gallery>
                                    <Item
                                        width={imageOrGifWidth}
                                        height={imageOrGifHeight}
                                        original={imageOrGif}
                                        alt={imageOrGifAlt}
                                    >
                                        {({ ref, open }) => (
                                            // @ts-expect-error
                                            <div className="image_mask" ref={ref} onClick={open}>
                                                <img src={glass} alt="zoom image" />
                                            </div>
                                        )}
                                    </Item>
                                </Gallery>
                            )}
                        </div>
                    </div>
                    <div className="col-lg-6 ">
                        <h2>{title}</h2>
                        <p className="mb-5">{subtitle}</p>

                        <CustomButton buttonStyle="btn--yellow-red" to={buttonTo}>
                            {buttonText}
                        </CustomButton>
                    </div>
                </div>
            </div>
        </div>
    );
};
Builder.registerComponent(YieldManagerLifetimeValue, {
    name: "YieldManagerLifetimeValue",
    inputs: toMutable(inputs),
});
