import type { ImageDataLike } from "gatsby-plugin-image";

export type Bg = "dark" | "light";
export type HubspotContentType =
    | "standard-page"
    | "site-page"
    | "landing-page"
    | "listing-page"
    | "blog-post"
    | "knowledge-article";

export type FaqData = {
    question: string;
    answer: string;
};

export type BlogPostData = {
    id: string;

    fields: {
        slug: string;
        readingTime?: {
            minutes: number;
        };
        body?: string;
        templateKey?: string;
    };

    html: string;
    featuredImageFileExternal?: ImageDataLike;
    frontmatter: {
        date: string;
        author: string;
        featuredImage?: string;
        authorSelect?: string;
        title: string;
        description: string;
        relatedFeatures: string[];
        tags?: string[];
        faq?: FaqData[];
        typeOfPost: string;
        pageUrl?: string;
    };
};

export type AuthorsCollection = {
    name: string;
    position: string;
    email: string;
    shortbio: string;
    authorimage: string;
};

export type EbookData = {
    id: string;
    link: string;
    featuredImageFileExternal: ImageDataLike;
};

export type NewsData = {
    id: string;
    featuredImage: string;
    hoverLogo: string;
    title: string;
    link: string;
    featuredImageFileExternal: ImageDataLike;
};

export type VideoData = {
    id: string;
    link: string;
    title: string;
    featuredImageFileExternal: ImageDataLike;
};

export type BannersData = {
    id: string;
    featuredImage: string;
    featuredImageFileExternal: ImageDataLike;
    link: string;
    title: string;
};

export type SuccessStoriesData = {
    fields: {
        slug: string;
    };
    clientLogoHover: string;
    id: string;
    featuredImage: string;
    featuredImageFileExternal: ImageDataLike;
    body: string;
    templateKey: string;
    video?: string;
    pdf?: string;
    title: string;
    sidebar: string;
    description: string;
    category: string;
    clientLogoFileExternal: ImageDataLike;
    relatedFeatures?: string[];
    sidebarImage: string;
    sidebarImageFileExternal: ImageDataLike;
    date: string;
};

export type TermsData = {
    id: string;
    fields: {
        slug: string;
    };
    title: string;
    description: string;
};

export type PodcastsData = {
    id: string;
    date: string;
    title: string;
    body: string;
    description: string;
    plannedTime: string;
    linkURL: string;
    linkText: string;
};

export type TCareers = {
    id: string;
    title: string;
    description: string;
    body: string;
    category: string;
    emailReceiver: string;
    location: string;
    isFullTime: boolean;
    fields: {
        slug: string;
    };
};

export type TAcademy = {
    title: string;
    description: string;
    author: string;
    duration: string;
    amountOfLectures: string;
    level: string;
    link: string;
    linkText: string;
    topic: string;
    featuredImageFileExternal: ImageDataLike;
    featuredImage: string;
};

export type TFooter = {
    title: string;
    description: string;
    resources: {
        title: string;
        link: string;
    }[];
    products: {
        title: string;
        link: string;
    }[];
    assertiveYield: {
        title: string;
        link: string;
    }[];
};

export type TDocs = {
    id: string;
    html: string;
    frontmatter: {
        title: string;
    };
    fields: {
        subRoute: string;
        slug: string;
        body: string;
        showOnTheWebsite: string;
        pathToMD: string;
    };
};

export type TDocsCategory = {
    subRoute: string;
    subRouteItems: {
        path: string;
        title: string;
        doc: string;
        showOnTheWebsite: boolean;
    };
};

export type TAds = {
    horizontalAdFileExternal: ImageDataLike;
    horizontalAd: string;
    verticalAdFileExternal: ImageDataLike;
    verticalAd: string;
    title: string;
    horizontalAdFullWidthTitle: string;
    horizontalAdFullWidthDescription: string;
    horizontalAdFullWidthLink: string;
    horizontalAdFullWidthLinkText: string;
    horizontalAdFullWidthImage: string;
    horizontalAdFullWidthImageFileExternal: ImageDataLike;
    horizontalAdLink: string;
    verticalAdLink: string;
};

type DeepWriteable<T> = { -readonly [P in keyof T]: DeepWriteable<T[P]> };
export const toMutable = <T>(t: T): DeepWriteable<T> => t as any;
