export const OrganizationBasedPlacements = [
    "request_reduction_server_latency",
    "traffic_shaping_server_samples_predict",
    "traffic_shaping_server_samples_profile",
    "traffic_shaping_server_floor_bandit",
    "traffic_shaping_server_reduction_overview",
    "pbs_account_usage",
    "traffic_shaping_server_rpmm",
] as const;
export const OrganizationBasedPlacementsRequireEntities = [
    //
    "discrepancy_revenue",
    "financial",
    "demand",
] as const;

export const EntityBasedPlacements = [
    "buy_campaign",
    "buy_adset",
    "buy_ad",
    "industry",
    "ad_revenue",
    "session_revenue",
    "session_time",
    "site",
    "spent_by_section",
    "request_reduction_client_v2",
    "view_dispersion",
    "error_log",
    "web",
    "error_analytics",
    "flooring",
    "segments",
    "yield",
    "prebid",
] as const;

export const AllOrganizationRequiredPlacements = [
    ...OrganizationBasedPlacements,
    ...OrganizationBasedPlacementsRequireEntities,
] as const;

export const ALL_PLACEMENTS = [
    ...EntityBasedPlacements,
    ...OrganizationBasedPlacements,
    ...OrganizationBasedPlacementsRequireEntities,
] as const;

export type ReportPlacementKeyType = (typeof ALL_PLACEMENTS)[number];

export const includesPlacements = (placements: readonly ReportPlacementKeyType[], placement: ReportPlacementKeyType) =>
    placements.includes(placement);
