import { CustomRangeConfig } from "../../codecs/custom-range-config.codec";
import { manualISSStats } from "../../utils/iss";
import { EdaPlacementBuilder } from "../lib";
import { CalculationType, OptimizationObjective, type PublicDimensionConfig } from "../types";
import yield_ from "./yield";
import { CalculatedYieldMetricsIds } from "./yield/yield.calculated-metrics";

const CALC_IDS = [
    "site_report_clicks",
    "site_report_sessions",
    "site_report_session_click_ratio",
    "site_report_cpc",
    "site_report_spent",
    "site_report_roi",
    "site_report_roi_with_forecast",
    "site_report_profit",
    "site_report_profit_with_forecast",
    "site_report_click_rpm",
    "site_report_click_rpm_with_forecast",
    "site_report_click_revenue",
    "site_report_click_revenue_with_forecast",
    "site_report_cps",
    "site_report_cpai",
    "site_report_new_sessions",
    "site_report_ffi",
    ...CalculatedYieldMetricsIds,
] as const;
export type SiteReportMetricsType = (typeof CALC_IDS)[number];

const RAW_METRICS = {
    ...yield_.rawMetrics,
    clicks_bought: {},
    spent: {},
} as const;

const DIMENSIONS = {
    site_report_platform: {
        label: "Platform",
    },
    site_report_site: {
        label: "Site",
    },
    site_report_site_name: {
        label: "Site Name",
    },
    site_report_site_url: {
        label: "Site URL",
    },
    site_report_site_id: {
        label: "Site ID",
    },
    site_report_campaign_name: {
        label: "Campaign Name",
    },
    site_report_campaign_id: {
        label: "Campaign ID",
    },
    site_report_subAccountId: {
        label: "Sub Account ID",
    },
    site_report_subAccountName: {
        label: "Sub Account Name",
    },
} as const satisfies Record<string, Omit<PublicDimensionConfig, "id">>;

export type SiteReportDimensionsIds = keyof typeof DIMENSIONS;

export default new EdaPlacementBuilder()
    .withCustomRange(CustomRangeConfig.date)
    .withTimeRanges(["Today", "Yesterday", "Last 7 Days", "Last 30 Days", "This Month", "Last Month"])
    .withTimeGranularities(["by_day", "by_week", "by_month"])
    .withRawMetrics(RAW_METRICS)
    .withCalculatedMetricsIds(CALC_IDS)
    .withCalculatedMetrics({
        ...yield_.metrics,
        //site
        site_report_clicks: {
            label: "Clicks Bought",
            explanation: "Clicks bought",
            formula: m => m.clicks_bought,
            calculationType: CalculationType.Sum,
            objective: OptimizationObjective.NoObjective,
        },
        site_report_sessions: {
            label: "New Sessions",
            explanation: "User Sessions",
            formula: m => m.session_starts,
            calculationType: CalculationType.Sum,
            objective: OptimizationObjective.Maximize,
        },
        site_report_session_click_ratio: {
            label: "Session Click Ratio",
            explanation: "New Sessions / Clicks",
            formula: m => m.site_report_sessions / m.site_report_clicks,
            calculationType: CalculationType.ProportionRatio,
            objective: OptimizationObjective.Maximize,
        },
        site_report_cpc: {
            label: "CPC",
            explanation: "Cash per Click",
            formula: m => m.site_report_spent / m.site_report_clicks,
            // We receive aggregated data of spent & clicks.
            // We use the std of averages to approximate the real std.
            issStats: manualISSStats.site_spent_per_click,
            calculationType: CalculationType.Mean,
            decimalPlaces: 3,
            objective: OptimizationObjective.Minimize,
        },
        site_report_spent: {
            label: "Spent",
            explanation: "Amount spent",
            formula: m => m.spent,
            calculationType: CalculationType.Sum,
            objective: OptimizationObjective.Minimize,
        },
        site_report_roi: {
            label: "ROI",
            explanation: "Return on Investment (Revenue / Spent)",
            formula: m => m.revenue / m.site_report_spent,
            // If we interpret ROI as CPC/REV_PER_CLICK we can use one of the children metrics to calculate significance.
            // It's a hack but we can't do better than this.
            issStats: manualISSStats.site_revenue_per_click,
            calculationType: CalculationType.Mean,
            objective: OptimizationObjective.Maximize,
        },
        site_report_roi_with_forecast: {
            label: "ROI with Forecast",
            explanation: "Return on Investment (Revenue / Spent) incl. DA Forecast",
            formula: m => m.revenue_with_forecast / m.site_report_spent,
            // If we interpret ROI as CPC/REV_PER_CLICK we can use one of the children metrics to calculate significance.
            // It's a hack but we can't do better than this.
            issStats: manualISSStats.site_revenue_per_click,
            calculationType: CalculationType.Mean,
            objective: OptimizationObjective.Maximize,
        },
        site_report_profit: {
            label: "Profit",
            explanation: "Profit (Revenue - Spent)",
            formula: m => m.revenue - m.site_report_spent,
            calculationType: CalculationType.Sum,
            objective: OptimizationObjective.Maximize,
        },
        site_report_profit_with_forecast: {
            label: "Profit with Forecast",
            explanation: "Profit (Revenue - Spent) incl. DA Forecast",
            formula: m => m.revenue_with_forecast - m.site_report_spent,
            calculationType: CalculationType.Sum,
            objective: OptimizationObjective.Maximize,
        },
        site_report_click_rpm: {
            label: "Click RPM",
            explanation: "Revenue per mille (thousand) clicks",
            formula: m => (m.revenue / m.site_report_clicks) * 1000,
            // We receive aggregated data of spent & clicks.
            // We use the std of averages to approximate the real std.
            issStats: manualISSStats.site_revenue_per_click,
            calculationType: CalculationType.Mean,
            decimalPlaces: 3,
            objective: OptimizationObjective.Maximize,
        },
        site_report_click_rpm_with_forecast: {
            label: "Click RPM with Forecast",
            explanation: "Revenue with DA forecast per mille (thousand) clicks",
            formula: m => (m.revenue_with_forecast / m.site_report_clicks) * 1000,
            // We receive aggregated data of spent & clicks.
            // We use the std of averages to approximate the real std.
            issStats: manualISSStats.site_revenue_per_click,
            calculationType: CalculationType.Mean,
            decimalPlaces: 3,
            objective: OptimizationObjective.Maximize,
        },
        site_report_click_revenue: {
            label: "Click Revenue",
            explanation: "Revenue per acquired Click",
            formula: m => m.revenue / m.site_report_clicks,
            // We receive aggregated data of spent & clicks.
            // We use the std of averages to approximate the real std.
            issStats: manualISSStats.site_revenue_per_click,
            calculationType: CalculationType.Mean,
            decimalPlaces: 3,
            objective: OptimizationObjective.Maximize,
        },
        site_report_click_revenue_with_forecast: {
            label: "Click Revenue with Forecast",
            explanation: "Revenue with DA forecast per acquired Click",
            formula: m => m.revenue_with_forecast / m.site_report_clicks,
            // We receive aggregated data of spent & clicks.
            // We use the std of averages to approximate the real std.
            issStats: manualISSStats.site_revenue_per_click,
            calculationType: CalculationType.Mean,
            decimalPlaces: 3,
            objective: OptimizationObjective.Maximize,
        },
        site_report_cps: {
            label: "CPS",
            explanation: "Cost per Session",
            formula: m => m.site_report_spent / m.site_report_sessions,
            // We receive aggregated data of spent & clicks.
            // We use the std of averages to approximate the real std.
            issStats: manualISSStats.site_spent_per_new_sessions,
            calculationType: CalculationType.Mean,
            decimalPlaces: 3,
            objective: OptimizationObjective.Minimize,
        },
        site_report_cpai: {
            label: "CPMAI",
            explanation: "Cost per Mille (thousand) Ad Impressions",
            formula: m => (m.site_report_spent / m.impressions) * 1000,
            // We receive aggregated data of spent & clicks.
            // We use the std of averages to approximate the real std.
            issStats: manualISSStats.site_spent_per_impressions,
            calculationType: CalculationType.Mean,
            decimalPlaces: 3,
            objective: OptimizationObjective.Minimize,
        },
        site_report_new_sessions: {
            label: "New Sessions",
            explanation: "New Sessions from Bought Clicks",
            formula: m => m.site_report_sessions,
            calculationType: CalculationType.Sum,
            objective: OptimizationObjective.Maximize,
        },
        site_report_ffi: {
            label: "Prebid FFI",
            explanation: "Sum of the first 5 highest bids from Prebid, per session",
            formula: m => m.first_five_indicator / m.session_starts / 1000 / 1000,
            issStats: manualISSStats.site_ffi_per_session_starts,
            calculationType: CalculationType.Mean,
            objective: OptimizationObjective.Maximize,
        },
    })
    .withInitialMetrics([
        "site_report_clicks",
        "site_report_sessions",
        "site_report_session_click_ratio",
        "site_report_cpc",
        "site_report_spent",
        "revenue",
        "site_report_roi",
    ])
    .withMetricTable({
        tabMeta: {
            spent: {
                name: "Spent",
                explanation: "Spent related metrics and suggestions of relevant Yield metrics",
            },
            ...yield_.metricTable.tabMeta,
        },
        columnNames: {
            // if needed, add the column names to the yield setup
            ...yield_.metricTable.columnNames,
        },
        tableMapping: {
            spent: {
                combined: [
                    "site_report_session_click_ratio",
                    "site_report_roi",
                    "site_report_roi_with_forecast",
                    "site_report_profit",
                    "site_report_profit_with_forecast",
                    "site_report_click_rpm",
                    "site_report_click_rpm_with_forecast",
                    "site_report_click_revenue",
                    "site_report_click_revenue_with_forecast",
                    "site_report_cps",
                    "site_report_cpai",
                ],
                spent: ["site_report_clicks", "site_report_spent", "site_report_cpc"],
                yield: [
                    "site_report_new_sessions",
                    "revenue",
                    "revenue_with_forecast",
                    "session_rpm",
                    "session_rpm_with_forecast",
                    "site_report_ffi",
                ],
                other: ["ads_per_session"],
            },
            ...yield_.metricTable.tableMapping,
        },
    })
    .withDimensions(DIMENSIONS);
