import { StaticImage } from "gatsby-plugin-image";
import { Builder } from "@builder.io/react";
import { Image as BuilderImage } from "@builder.io/react";
import { SIGNUP_LINK } from "components/constants";
import CustomButton from "components/CustomButton";
import { toMutable } from "types/common";
import * as styles from "./styles.module.scss";

const inputs = [
    {
        name: "suptitle",
        type: "text",
        defaultValue: "Industry Insights",
    },
    {
        name: "title",
        type: "text",
        defaultValue: "Industry Insights for a Performance that will be hard to beat",
    },
    {
        name: "subtitle",
        type: "text",
        defaultValue:
            "Competitive Intelligence by analyzing market trends with extraordinarily granulary data, analyzing profitable partners, trending ads formats, placements and identifying new revenue opportunities, such as demand for specific ad sizes to define your optimal price.",
    },
    {
        name: "buttonText",
        type: "text",
        defaultValue: "Try it Out, It's Free",
    },
    {
        name: "linkTo",
        type: "text",
        defaultValue: SIGNUP_LINK,
        required: true,
    },
    {
        name: "imageOrGif",
        type: "file",
        allowedFileTypes: ["jpg", "png", "jpeg", "gif", "svg"],
        required: true,
    },
    {
        name: "imageOrGifHover",
        type: "file",
        allowedFileTypes: ["jpg", "png", "jpeg", "gif", "svg"],
    },
    {
        name: "imageOrGifAlt",
        type: "text",
        defaultValue: "Gain Bidder Intelligence",
    },
] as const;

const IndustryInsightsHero: BFC<typeof inputs> = ({
    suptitle,
    title,
    subtitle,
    buttonText,
    linkTo,
    imageOrGif,
    imageOrGifHover,
    imageOrGifAlt,
}) => {
    return (
        <div className="container">
            <div className={`${styles.firstGlow} gradient`}>
                <StaticImage src="./images/industry-insights-hero-first-glow.svg" alt="" />
            </div>
            <div className={`${styles.secondGlow} gradient`}>
                <StaticImage src="./images/industry-insights-hero-second-glow.svg" alt="" />
            </div>
            <div className={styles.wrapper}>
                <div className={styles.mainContent}>
                    <p className={styles.suptitle}>{suptitle}</p>
                    <h1 className={styles.title}>{title}</h1>
                    <p className={styles.subtitle}>{subtitle}</p>
                    <div className={styles.btn}>
                        <CustomButton to={linkTo}>{buttonText}</CustomButton>
                    </div>
                    <p className={styles.note}>No credit card required</p>
                </div>
                <div className={styles.image}>
                    {imageOrGifHover ? (
                        <img
                            onMouseOver={e => (e.currentTarget.src = imageOrGifHover)}
                            onMouseOut={e => (e.currentTarget.src = imageOrGif!)}
                            src={imageOrGif}
                            alt={imageOrGifAlt}
                            className={styles.image}
                        />
                    ) : (
                        //@ts-ignore
                        <BuilderImage altText={imageOrGifAlt} image={imageOrGif} sizes="100%" />
                    )}
                </div>
            </div>
        </div>
    );
};

Builder.registerComponent(IndustryInsightsHero, {
    name: "industryInsightsHero",
    inputs: toMutable(inputs),
});
