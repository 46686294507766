import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { Builder } from "@builder.io/react";
import { Image as BuilderImage } from "@builder.io/react";
import { BOOK_CALL_LINK } from "components/constants";
import CustomButton from "components/CustomButton";
import { toMutable } from "types/common";
import logoGif from "./images/logo.gif";
import * as styles from "./styles.module.scss";

const inputs = [
    {
        name: "title",
        type: "text",
        defaultValue: "We aim to provide high quality data",
    },
    {
        name: "subtitle",
        type: "list",
        subFields: [
            {
                name: "paragraph",
                type: "text",
            },
        ],
        defaultValue: [
            {
                paragraph:
                    "And all this in a matter of minutes. Bypass time-consuming complex technical tasks in order to provide the data required for successful monetization.",
            },
            {
                paragraph: "On top of that you will get enormous Granular Data as you have never seen before.",
            },
        ],
    },
    {
        name: "list",
        type: "list",
        subFields: [
            {
                name: "title",
                type: "text",
            },
            {
                name: "subtitle",
                type: "text",
            },
            {
                name: "img",
                type: "file",
                allowedFileTypes: ["jpg", "png", "jpeg", "gif", "svg"],
                required: true,
            },
            {
                name: "imgAlt",
                type: "text",
            },
            {
                name: "imgWidth",
                type: "number",
            },
            {
                name: "imgHeight",
                type: "number",
            },
            {
                name: "itemLinkTo",
                type: "text",
                defaultValue: "/",
                required: true,
            },
        ],
        defaultValue: [
            {
                title: "Monitoring",
                subtitle:
                    "Analyze, Cross- reference & execute decisions in minutes with real-time data. Discover how Assertive Monitoring and Alerts can help you stay on-top of the game.",
                itemLinkTo: "/real-time-alerts",
            },
            {
                title: "Dashboards",
                subtitle:
                    "Revenue collected from multiple sources? Spend data with ROI? Monitoring IVT, 2 click penalty and Core Web Vitals? Analyze them all in one place, or divided in dashboards.",
                itemLinkTo: "/dashboards",
            },
            {
                title: "Slice & Dice Data Cube",
                subtitle:
                    "AY platform assists with granular data analysis by allowing you to slice and dice cube datasets through multidimensional overview, metrics, and filters.",
                itemLinkTo: "/slice-and-dice",
            },
            {
                title: "Industry Insights",
                subtitle: "Harness the power of market intelligence to identify demands and sales opportunities",
                itemLinkTo: "/industry-insights",
            },
        ],
    },
    {
        name: "buttonText",
        type: "text",
        defaultValue: "Request Demo",
    },
    {
        name: "linkTo",
        type: "text",
        defaultValue: BOOK_CALL_LINK,
        required: true,
    },
] as const;

export const WeAimToProvideQualityData: BFC<typeof inputs> = ({ title, subtitle, list, buttonText, linkTo }) => {
    return (
        <div className="container">
            <div className={`${styles.firstGlow} gradient`}>
                <StaticImage src="./images/we-aim-first-glow.svg" alt="" />
            </div>

            <div className={styles.wrapper}>
                <div className={styles.mainContent}>
                    <div className="mb-4 d-none d-lg-block">
                        <img src={logoGif} className={styles.icon} alt="logo" loading="lazy" width="59" height="57" />
                    </div>
                    <h2 className={styles.title}>{title}</h2>

                    <div className={styles.subtitle}>
                        {subtitle?.map(({ paragraph }, index) => <p key={index}>{paragraph}</p>)}
                    </div>
                    <div className={styles.btnWrapper}>
                        <CustomButton to={linkTo}>{buttonText}</CustomButton>
                    </div>
                </div>
                <div className={styles.navigationList}>
                    {list?.map(({ title, subtitle, img, itemLinkTo, imgAlt, imgWidth, imgHeight }, index) => (
                        <Link to={itemLinkTo} className={styles.item} key={index}>
                            <div
                                style={{
                                    width: imgWidth,
                                    height: imgHeight,
                                }}
                                className={styles.image}
                            >
                                {/* @ts-ignore */}
                                <BuilderImage image={img} altText={imgAlt} lazy />
                            </div>
                            <h4>{title}</h4>
                            <p>{subtitle}</p>
                            <div className={styles.linkWrapper}>
                                <span className={styles.linkText}>Learn more</span>
                                <StaticImage src="./images/arrow.svg" alt="arrow" />
                            </div>
                        </Link>
                    ))}
                </div>
            </div>
        </div>
    );
};

Builder.registerComponent(WeAimToProvideQualityData, {
    name: "weAimToProvideQualityData",
    inputs: toMutable(inputs),
});
