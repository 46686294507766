import { Gallery, Item } from "react-photoswipe-gallery";
import { Builder } from "@builder.io/react";
import { Image as BuilderImage } from "@builder.io/react";
import { SIGNUP_LINK } from "components/constants";
import CustomButton from "components/CustomButton";
import { toMutable } from "types/common";
import glass from "./images/glass.svg";
import * as styles from "./styles.module.scss";

const inputs = [
    {
        name: "suptitle",
        type: "text",
        defaultValue: "DETECT FLUCTUATIONS IN REAL-TIME",
    },
    {
        name: "title",
        type: "text",
        defaultValue: "Set KPIs Discrepancy Range",
        required: true,
    },
    {
        name: "subtitle",
        type: "longText",
        defaultValue:
            "Keep track of how products are performing in the market by configuring % ranges of KPIs discrepancies and get notifications according to selected severity degree and frequency. Be aware in real-time when and where there's a discrepancy, like a decrease of a CTR of a specific category.",
        required: true,
    },
    {
        name: "buttonText",
        type: "text",
        defaultValue: "Check out all alert filters",
        required: true,
    },
    {
        name: "linkTo",
        type: "text",
        defaultValue: SIGNUP_LINK,
        required: true,
    },
    {
        name: "switchMagnifier",
        type: "boolean",
        defaultValue: true,
        enum: [
            {
                value: true,
                label: "Yes",
            },
            {
                value: false,
                label: "No",
            },
        ],
    },
    {
        name: "imageOrGif",
        type: "file",
        allowedFileTypes: ["jpg", "png", "jpeg", "gif", "svg"],
    },
    {
        name: "imageOrGifHover",
        type: "file",
        allowedFileTypes: ["jpg", "png", "jpeg", "gif", "svg"],
    },
    {
        name: "imageOrGifAlt",
        type: "text",
        defaultValue: "Gain Bidder Intelligence",
    },
    {
        name: "imageOrGifWidth",
        type: "number",
        defaultValue: 1113,
    },
    {
        name: "imageOrGifHeight",
        type: "number",
        defaultValue: 860,
    },
] as const;
const MonitoringDetect: BFC<typeof inputs> = ({
    suptitle,
    title,
    subtitle,
    linkTo,
    switchMagnifier,
    buttonText,
    imageOrGif,
    imageOrGifHover,
    imageOrGifAlt,
    imageOrGifWidth,
    imageOrGifHeight,
}) => {
    return (
        <>
            <div className="component-wrapper">
                <div className="container ">
                    <div className="row align-items-center gx-5">
                        <div className="col-lg-6 order-lg-1 mt-4 mb-4 mt-lg-0 mb-lg-0">
                            <div className="mask-container  position-relative ">
                                {imageOrGifHover ? (
                                    <img
                                        onMouseOver={e => (e.currentTarget.src = imageOrGifHover)}
                                        onMouseOut={e => (e.currentTarget.src = imageOrGif!)}
                                        src={imageOrGif}
                                        alt={imageOrGifAlt}
                                        className="img-fluid"
                                    />
                                ) : (
                                    //@ts-ignore
                                    <BuilderImage image={imageOrGif} lazy sizes="100%" altText={imageOrGifAlt} />
                                )}
                                {switchMagnifier && !imageOrGifHover && (
                                    <Gallery>
                                        <Item
                                            width={imageOrGifWidth}
                                            height={imageOrGifHeight}
                                            original={imageOrGif}
                                            alt={imageOrGifAlt}
                                        >
                                            {({ ref, open }) => (
                                                // @ts-expect-error
                                                <div className="image_mask" ref={ref} onClick={open}>
                                                    <img src={glass} alt="zoom image" />
                                                </div>
                                            )}
                                        </Item>
                                    </Gallery>
                                )}
                            </div>
                        </div>
                        <div className="col-lg-6 order-2 order-lg-1 mt-5 mt-lg-0">
                            <p className={styles.suptitle}>{suptitle}</p>
                            <h2>{title}</h2>
                            <p className="mb-5">{subtitle}</p>
                            <CustomButton buttonStyle="btn--primary" to={linkTo}>
                                {buttonText}
                            </CustomButton>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

Builder.registerComponent(MonitoringDetect, {
    name: "monitoringDetect",
    inputs: toMutable(inputs),
});
